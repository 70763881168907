import {Component} from "@angular/core";

@Component({
    selector: 'case4',
    templateUrl: './case4.component.html',
    styleUrls: ['./case4.component.scss'],
})

export class Case4Component {

}
