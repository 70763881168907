import {BrowserModule} from '@angular/platform-browser';
import {NgModule} from '@angular/core';
import {HttpClientModule} from '@angular/common/http';

import {AppRoutingModule} from '@/app-routing.module';
import {AppComponent} from './app.component';
import {MainComponent} from '@modules/main/main.component';
import {LoginComponent} from '@modules/login/login.component';
import {HeaderComponent} from '@modules/main/header/header.component';
import {FooterComponent} from '@modules/main/footer/footer.component';
import {MenuSidebarComponent} from '@modules/main/menu-sidebar/menu-sidebar.component';
import {BlankComponent} from '@pages/blank/blank.component';
import {ReactiveFormsModule} from '@angular/forms';
import {ProfileComponent} from '@pages/profile/profile.component';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {RegisterComponent} from '@modules/register/register.component';
import {DashboardComponent} from '@pages/dashboard/dashboard.component';
import {ToastrModule} from 'ngx-toastr';
import {MessagesComponent} from '@modules/main/header/messages/messages.component';
import {NotificationsComponent} from '@modules/main/header/notifications/notifications.component';

import {registerLocaleData} from '@angular/common';
import localeEn from '@angular/common/locales/en';
import {UserComponent} from '@modules/main/header/user/user.component';
import {ForgotPasswordComponent} from '@modules/forgot-password/forgot-password.component';
import {RecoverPasswordComponent} from '@modules/recover-password/recover-password.component';
import {LanguageComponent} from '@modules/main/header/language/language.component';
import {MainMenuComponent} from './pages/main-menu/main-menu.component';
import {SubMenuComponent} from './pages/main-menu/sub-menu/sub-menu.component';
import {MenuItemComponent} from './components/menu-item/menu-item.component';
import {ControlSidebarComponent} from './modules/main/control-sidebar/control-sidebar.component';
import {StoreModule} from '@ngrx/store';
import {authReducer} from './store/auth/reducer';
import {uiReducer} from './store/ui/reducer';
import {ProfabricComponentsModule} from '@profabric/angular-components';
import {defineCustomElements} from '@profabric/web-components/loader';
import {SidebarSearchComponent} from './components/sidebar-search/sidebar-search.component';
import {WebsiteComponent} from "@modules/website/website.component";
import {SwiperModule} from "swiper/angular";
import {TermsComponent} from "@modules/terms-and-conditions/terms.component";
import {PrivacyPolicyComponent} from "@modules/privacy-policy/privacy-policy.component";
import {AboutComponent} from "@modules/mssi/about-us/about.component";
import {CareersComponent} from "@modules/mssi/careers/careers.component";
import {ContactUsComponent} from "@modules/mssi/contact-us/contact-us.component";
import {ContactInformationComponent} from "@modules/mssi/contact-us/contact-information/contact-information.component";
import {LocationsComponent} from "@modules/mssi/contact-us/locations/locations.component";
import {HomeComponent} from "@modules/mssi/home-main/home/home.component";
import {ServicesComponent} from "@modules/mssi/home-main/services/services.component";
import {SolutionsComponent} from "@modules/mssi/home-main/solutions/solutions.component";
import {StaffingComponent} from "@modules/mssi/home-main/staffing/staffing.component";
import {ServiceDesignComponent} from "@modules/mssi/home-main/services/service-design/service-design.component";
import {ServiceSupportComponent} from "@modules/mssi/home-main/services/service-support/service-support.component";
import {ServiceProgramComponent} from "@modules/mssi/home-main/services/service-program/service-program.component";
import {AppDevelopmentComponent} from "@modules/mssi/home-main/solutions/app-development/app-development.component";
import {ArchitectureDesignComponent} from "@modules/mssi/home-main/solutions/architecture-design/architecture-design.component";
import {InfrastructureComponent} from "@modules/mssi/home-main/solutions/infrastructure/infrastructure.component";
import {OfficeSetupComponent} from "@modules/mssi/home-main/solutions/office-setup/office-setup.component";
import {OngoingSupportComponent} from "@modules/mssi/home-main/solutions/ongoing-support/ongoing-support.component";
import {RiskComponent} from "@modules/mssi/home-main/solutions/risk/risk.component";
import {RecruitingComponent} from "@modules/mssi/recruiting/recruiting.component";
import {CurrentJobsComponent} from "@modules/mssi/recruiting/current-jobs/current-jobs.component";
import {SubmitResumeComponent} from "@modules/mssi/recruiting/submit-resume/submit-resume.component";
import {MssiFooterComponent} from "@modules/mssi-footer/mssi.footer.component";
import {MssiHeaderComponent} from "@modules/mssi-header/mssi-header.component";
import {Case1Component} from "@modules/mssi/case-study/case1/case1.component";
import {Case2Component} from "@modules/mssi/case-study/case2/case2.component";
import {Case4Component} from "@modules/mssi/case-study/case4/case4.component";
import {Case5Component} from "@modules/mssi/case-study/case5/case5.component";
import {Case6Component} from "@modules/mssi/case-study/case6/case6.component";
import {Case7Component} from "@modules/mssi/case-study/case7/case7.component";
import {Case3Component} from "@modules/mssi/case-study/case3/case3.component";
import {NgxSpinnerModule} from "ngx-spinner";

defineCustomElements();
registerLocaleData(localeEn, 'en-EN');

@NgModule({
  declarations: [
    AppComponent,
    MainComponent,
    LoginComponent,
    HeaderComponent,
    FooterComponent,
    MenuSidebarComponent,
    BlankComponent,
    ProfileComponent,
    RegisterComponent,
    DashboardComponent,
    MessagesComponent,
    NotificationsComponent,
    UserComponent,
    ForgotPasswordComponent,
    RecoverPasswordComponent,
    LanguageComponent,
    MainMenuComponent,
    SubMenuComponent,
    MenuItemComponent,
    ControlSidebarComponent,
    SidebarSearchComponent,
    WebsiteComponent,
    TermsComponent,
    PrivacyPolicyComponent,
    AboutComponent,
    CareersComponent,
    ContactUsComponent,
    ContactInformationComponent,
    LocationsComponent,
    HomeComponent,
    ServicesComponent,
    ServiceDesignComponent,
    ServiceSupportComponent,
    ServiceProgramComponent,
    ServiceSupportComponent,
    SolutionsComponent,
    AppDevelopmentComponent,
    ArchitectureDesignComponent,
    InfrastructureComponent,
    OfficeSetupComponent,
    OngoingSupportComponent,
    RiskComponent,
    StaffingComponent,
    RecruitingComponent,
    CurrentJobsComponent,
    SubmitResumeComponent,
      MssiFooterComponent,
      MssiHeaderComponent,
      Case1Component,
      Case2Component,
      Case4Component,
      Case5Component,
      Case6Component,
      Case7Component,
      Case3Component

  ],
  imports: [
    BrowserModule,
    StoreModule.forRoot({auth: authReducer, ui: uiReducer}),
    HttpClientModule,
    AppRoutingModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    ToastrModule.forRoot({
      timeOut: 3000,
      positionClass: 'toast-top-right',
      preventDuplicates: true
    }),
    ProfabricComponentsModule,
    SwiperModule,
      NgxSpinnerModule
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule {
}
