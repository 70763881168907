import {Component, ViewEncapsulation} from "@angular/core";

@Component({
    selector: 'about-us',
    templateUrl: './about.component.html',
    styleUrls: ['./about.component.scss'],
})

export class AboutComponent {

}