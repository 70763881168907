import {Component} from "@angular/core";

@Component({
    selector: 'case1',
    templateUrl: './case1.component.html',
    styleUrls: ['./case1.component.scss'],
})

export class Case1Component {

}
