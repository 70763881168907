<app-mssi-header></app-mssi-header>
<div class="container-fluid bg-light ">
    <div class="row "></div>
    <div class="row mt-5"></div>
    <div class="row mt-5">
        <div class="col-lg-2 col-12"></div>
        <div class="col-lg-6 col-12">
            <div class="heading">
                <p class="text-bold" style="align-content: center;">Current Jobs</p>
            </div>
        </div>
        <div class="col-lg-3 col-12">
           <p class="text-muted"><a [routerLink]="['/home']" class="text-muted">Home</a> >>Current Jobs </p>
        </div>
    </div>
        <div id="carouselExampleAutoplaying" class="carousel slide" data-bs-ride="carousel">
            <div class="carousel-inner">
                <div class="carousel-item active">
                    <img src="../../../assets/img/c1.jpg" class="d-block w-100" alt="..." style="width: 150px">
                </div>
                <div class="carousel-item">
                    <img src="../../../assets/img/c2.jpg" class="d-block w-100" alt="..." style="width: 150px">
                </div>
                <div class="carousel-item">
                    <img src="../../../assets/img/c3.jpg" class="d-block w-100" alt="..." style="width: 150px">
                </div>
                <div class="carousel-item">
                    <img src="../../../assets/img/c4.jpg" class="d-block w-100" alt="..." style="width: 150px">
                </div>
            </div>
            <button class="carousel-control-prev" type="button" data-bs-target="#carouselExampleAutoplaying" data-bs-slide="prev">
                <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                <span class="visually-hidden">Previous</span>
            </button>
            <button class="carousel-control-next" type="button" data-bs-target="#carouselExampleAutoplaying" data-bs-slide="next">
                <span class="carousel-control-next-icon" aria-hidden="true"></span>
                <span class="visually-hidden">Next</span>
            </button>
        </div>

    <div class="row mt-5 text-center">
      <div class="col-12 mb-5">
          <img src="./assets/img/no-jobs.jpeg" class="img-fluid" alt="no-jobs">
      </div>
    </div>
</div>
<app-mssi-footer></app-mssi-footer>
