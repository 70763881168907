import {Component, HostBinding} from '@angular/core';
import {DateTime} from 'luxon';


@Component({
    selector: 'app-mssi-header',
    templateUrl: './mssi-header.component.html',
    styleUrls: ['./mssi-header.component.scss']
})
export class MssiHeaderComponent {
    public currentYear: string = DateTime.now().toFormat('y');
}
