<app-mssi-header></app-mssi-header>
<div class="container-fluid ">
    <div class="row"></div>
    <div class="row"></div>

    <div class="row mt-5">
        <div id="carouselExampleCaptions" class="carousel slide mt-5" data-bs-ride="carousel">
            <div class="carousel-indicators">
                <button type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide-to="0" class="active"
                        aria-current="true" aria-label="Slide 1"></button>
                <button type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide-to="1"
                        aria-label="Slide 2"></button>
                <button type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide-to="2"
                        aria-label="Slide 3"></button>
                <button type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide-to="3"
                        aria-label="Slide 3"></button>
            </div>
            <div class="carousel-inner">
                <div class="carousel-item active">
                    <img src="../../../assets/img/home_page_1.jpg" class="d-block w-100" alt="home"
                         style="width: 150px">
                    <div class="carousel-caption d-none d-md-block">
                    </div>
                </div>
                <div class="carousel-item">
                    <img src="../../../assets/img/home_page_2.jpg" class="d-block w-100" alt="doctor2"
                         style="width: 150px">
                    <div class="carousel-caption d-none d-md-block">
                    </div>
                </div>
                <div class="carousel-item">
                    <img src="../../../assets/img/home_page_3.jpg" class="d-block w-100" alt="doctor3"
                         style="width: 150px">
                    <div class="carousel-caption d-none d-md-block">
                    </div>
                </div>
                <div class="carousel-item">
                    <img src="../../../assets/img/home_page_4.jpg" class="d-block w-100" alt="doctor3"
                         style="width: 150px">
                    <div class="carousel-caption d-none d-md-block">
                    </div>
                </div>
            </div>
            <button class="carousel-control-prev" type="button" data-bs-target="#carouselExampleCaptions"
                    data-bs-slide="prev">
                <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                <span class="visually-hidden">Previous</span>
            </button>
            <button class="carousel-control-next" type="button" data-bs-target="#carouselExampleCaptions"
                    data-bs-slide="next">
                <span class="carousel-control-next-icon" aria-hidden="true"></span>
                <span class="visually-hidden">Next</span>
            </button>
        </div>
    </div>


    <div class="container-fluid col-lg-11 mt-5">
        <div class="row">
            <div class="col-lg-4 col-12">
                <div class="card" style="border: 1px solid green; height: 21rem">
                    <div class="card-header bg-transparent text-center">
                        <a [routerLink]="['/solutions']"><img src="../../../assets/img/handshake.png"
                                                              style="width: 60px"></a>
                    </div>
                    <div class="card-body text-muted">
                        <h6 class="text-center text-muted"><a [routerLink]="['/solutions']"
                                                              style="color:black;font-weight: 700;font-size: 18px">Solutions</a>
                        </h6>
                        <p class="card-text text-center">Through a combination of technological infrastructure and
                            applications expertise and proven success, MSSI Delivers Solutions to help your company
                            solve its business problems and objectives</p>
                    </div>
                    <div class="card-footer bg-transparent border-success text-center">
                        <a [routerLink]="['/solutions']" class="link-black" style="font-size: 14px">Learn More</a>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-12">
                <div class="card" style="border: 1px solid green; height: 21rem">
                    <div class="card-header bg-transparent text-center">
                        <a [routerLink]="['/services']"><img src="../../../assets/img/gears.png" alt="Card image"
                                                             style="width: 60px"></a>
                    </div>
                    <div class="card-body">
                        <h6 class="text-muted text-center"><a [routerLink]="['/services']"
                                                              style="color:black;font-weight: 700;font-size: 18px">Services</a>
                        </h6>
                        <p class="card-text text-muted text-center">With teams of Subject Matter Experts, ranging from
                            Project Manager to Infrastructure Architects, MSSI can meet your company's most pressing
                            needs
                        </p>
                    </div>
                    <div class="card-footer bg-transparent border-success text-center">
                        <a [routerLink]="['/services']" class="link-black" style="font-size: 14px">Learn More</a>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-12">
                <div class="card" style="border: 1px solid green; height: 21rem">
                    <div class="card-header bg-transparent text-center">
                        <a [routerLink]="['/staffing']"><img src="../../../assets/img/group.png" alt="Card image"
                                                             style="width: 60px"></a>
                    </div>
                    <div class="card-body">
                        <h6 class="text-muted text-center"><a [routerLink]="['/staffing']"
                                                              style="color:black;font-weight: 700;font-size: 18px">Staffing</a>
                        </h6>
                        <p class="card-text text-muted text-center">Whether you are looking to fill an open position, or
                            looking for short/long term resources, MSSI can help.
                        </p>
                    </div>
                    <div class="card-footer bg-transparent border-success text-center">
                        <a [routerLink]="['/staffing']" class="link-black" style="font-size: 14px">Learn More</a>
                    </div>
                </div>
            </div>
        </div>
    </div>

</div>
<div class="row mt-5"></div>


<app-mssi-footer></app-mssi-footer>
