import {Component} from "@angular/core";

@Component({
    selector: 'current-jobs',
    templateUrl: './current-jobs.component.html',
    styleUrls: ['./current-jobs.component.scss'],
})

export class CurrentJobsComponent {

}