<app-mssi-header></app-mssi-header>
<div class="container-fluid ">
    <div class="row "></div>
    <div class="row mt-5"></div>
    <div class="row mt-5">
        <div id="carouselExampleAutoplaying" class="carousel slide" data-bs-ride="carousel">
            <div class="carousel-inner">
                <div class="carousel-item active">
                    <img src="../../../assets/img/on.jpg" class="d-block w-100" alt="..." style="width: 150px">
                </div>
                <div class="carousel-item">
                    <img src="../../../assets/img/on1.jpg" class="d-block w-100" alt="..." style="width: 150px">
                </div>
                <div class="carousel-item">
                    <img src="../../../assets/img/on2.jpg" class="d-block w-100" alt="..." style="width: 150px">
                </div>
                <div class="carousel-item">
                    <img src="../../../assets/img/on3.jpg" class="d-block w-100" alt="..." style="width: 150px">
                </div>
                <div class="carousel-item">
                    <img src="../../../assets/img/on4.jpg" class="d-block w-100" alt="..." style="width: 150px">
                </div>
            </div>
            <button class="carousel-control-prev" type="button" data-bs-target="#carouselExampleAutoplaying"
                    data-bs-slide="prev">
                <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                <span class="visually-hidden">Previous</span>
            </button>
            <button class="carousel-control-next" type="button" data-bs-target="#carouselExampleAutoplaying"
                    data-bs-slide="next">
                <span class="carousel-control-next-icon" aria-hidden="true"></span>
                <span class="visually-hidden">Next</span>
            </button>
        </div>
    </div>
    <div class="row">
        <div class="col-sm-1 col-12"></div>
        <div class="col-lg-10 col-12 mt-3 text-muted">
            <p class="text-bold"><a href="#">Home</a>>>Ongoing Support</p>
            <p>The benefits of our service desk go well beyond basic help-desk support. In addition to providing
                multi-tiered end-user assistance, our service desk acts as a centralized hub for any array of services.
                From proactive monitoring and maintenance to incident, change, problem management. The service desk
                delivers a highly responsive IT management solution designed to empower your team to focus on more
                pressing, strategic goals.</p>
            <ul>
                <li>Available 24x7x365</li>
                <li>Customizable end-user interfaces (phone, email, and/or online)</li>
                <li>Adhering to strict service-level agreements (SLA)</li>
                <li> Regulated by ITIL best practices</li>
                <li>Fully staffed with MSSI’s employees, not contractors, to ensure consistent service and
                    improvement-over-time
                </li>
            </ul>
            <p>Interested in how MSSI Ongoing Support can benefit your business?</p>
            <p><a [routerLink]="['/contact-information']">Contact US</a></p>
        </div>
        <div class="col-sm-1 col-12"></div>
    </div>
</div>
<div class="row mt-5"></div>
<app-mssi-footer></app-mssi-footer>
