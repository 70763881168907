<app-mssi-header></app-mssi-header>
<div class="container-fluid ">
    <div class="row "></div>
    <div class="row mt-5"></div>
    <div class="row mt-5">
        <img class="img-fluid" src="../../../assets/img/architecture.jpg">
    </div>
    <div class="row">
        <div class="col-sm-1 col-12"></div>
        <div class="col-lg-8 col-12 mt-3 text-muted">
            <p class="text-bold"><a href="#">Home</a>>>Architecture Design</p>
            <p>Today’s users expect IT Systems to be reliable and available at all times. Designing and architecting
                applications and infrastructure for dynamic, data-driven systems can be challenging.</p>
            <p class="mt-2">MSSI’s architecture design consultants can provide a technical evaluation and architectural
                recommendation for your business applications by reviewing system components such as servers, storage,
                databases, networks, and operating systems, that will deliver maximum uptime and, accommodate
                growth.</p>
            <p class="mt-2">Please click on the links to the right to review some of our customer success stories.</p>
            <p class="">Interested in how MSSI Architecture Design can benefit your business?</p>
            <p class=""><a [routerLink]="['/contact-information']">Contact Us</a></p>
        </div>
        <div class="col-lg-3 col-12 mt-4 text-muted">
            <ul>
                <li><a href="#">Case Study : Implementation of an Intercompany Consolidated Financial Management
                    System</a></li>
                <li><a href="#">Case Study : Data Center Assessment and Consolidation</a></li>
            </ul>
        </div>

    </div>

</div>
<app-mssi-footer></app-mssi-footer>
