<app-mssi-header></app-mssi-header>
<div class="container-fluid ">
    <div class="row "></div>
    <div class="row mt-5"></div>
    <div class="row mt-5">
        <div id="carouselExampleAutoplaying" class="carousel slide" data-bs-ride="carousel">
            <div class="carousel-inner">
                <div class="carousel-item active">
                    <img src="../../../assets/img/app.jpg" class="d-block w-100" alt="..." style="width: 150px">
                </div>
                <div class="carousel-item">
                    <img src="../../../assets/img/app1.jpg" class="d-block w-100" alt="..." style="width: 150px">
                </div>
                <div class="carousel-item">
                    <img src="../../../assets/img/app2.jpg" class="d-block w-100" alt="..." style="width: 150px">
                </div>
                <div class="carousel-item">
                    <img src="../../../assets/img/app3.jpg" class="d-block w-100" alt="..." style="width: 150px">
                </div>
                <div class="carousel-item">
                    <img src="../../../assets/img/app4.jpg" class="d-block w-100" alt="..." style="width: 150px">
                </div>
            </div>
            <button class="carousel-control-prev" type="button" data-bs-target="#carouselExampleAutoplaying"
                    data-bs-slide="prev">
                <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                <span class="visually-hidden">Previous</span>
            </button>
            <button class="carousel-control-next" type="button" data-bs-target="#carouselExampleAutoplaying"
                    data-bs-slide="next">
                <span class="carousel-control-next-icon" aria-hidden="true"></span>
                <span class="visually-hidden">Next</span>
            </button>
        </div>
        <!--        <img class="img-fluid" src="../../../assets/img/app.jpg">-->
    </div>
    <div class="row">
        <div class="col-lg-1 col-12"></div>
        <div class="col-lg-7 col-12 mt-5 text-muted">
            <p>Your Application Technology doesn’t stand still. Our application experts can develop and rollout a mobile
                application, tackle a complex system problem, replace your legacy systems or create custom systems.
            </p>
            <p class="mt-3">Our approach to application development involves working with your teams from architecture
                and design, to modernizing your software development processes, to providing flexible solutions.</p>
            <p class="mt-3">Let us develop your next application, clear your backlog, resolve your complex issues or
                scale your existing systems.</p>
            <ul>
                <li>Assessments and Strategies</li>
                <li>Planning and Roadmaps</li>
                <li>Enterprise Application Architecture</li>
                <li>Software Design Strategies and Patterns</li>
            </ul>
            <p>Interested in how MSSI Infrastructure Assessments can benefit your business?</p>
            <p><a [routerLink]="['/contact-information']">Contact Us</a></p>
        </div>
        <div class="col-lg-3 col-12 mt-5 text-muted">
            <ul>
                <li><a [routerLink]="['/case6']">Case Study : Mobile Application Development 1</a></li>
                <li><a [routerLink]="['/case7']">Case Study : Mobile Application Development 2</a></li>
            </ul>
        </div>
    </div>
</div>


<app-mssi-footer></app-mssi-footer>
