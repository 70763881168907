import {Component, HostBinding} from '@angular/core';
import {DateTime} from 'luxon';


@Component({
  selector: 'app-mssi-footer',
  templateUrl: './mssi.footer.component.html',
  styleUrls: ['./mssi.footer.component.scss']
})
export class MssiFooterComponent {
  public currentYear: string = DateTime.now().toFormat('y');
}
