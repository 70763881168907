import {Component} from "@angular/core";

@Component({
    selector: 'contact-us',
    templateUrl: './contact-us.component.html',
    styleUrls: ['./contact-us.component.scss'],
})

export class ContactUsComponent {

}