<app-mssi-header></app-mssi-header>
<div class="container-fluid mt-5">
    <div class="row">
        <div class="col-sm-1 col-12"></div>
        <div class="col-lg-11 col-12 mt-5">
            <p class="text-muted text-bold" style="font-size: 15px"><a [routerLink]="['/home']">Home</a> >>Data Center
                Assessment and Consolidation</p>
            <p class="text-muted text-bold font-italic"> CLIENT: A fortune 500 IT company with both hardware and
                software products used worldwide</p>
            <h5 class="mt-3 text-bold text-muted" style="font-size: 18px">BUSINESS PROBLEM:</h5>
            <p>The client had multiple small test data centers, with extensive infrastructure with little or no cost
                control, and duplication of effort and infrastructure
            </p>
            <h5 class="text-muted text-bold" style="font-size: 18px">SOLUTION:</h5>
            <ul>
                <li>Gather inventory of existing data centers, and determine functionality and purpose</li>
                <li>Design a single test data center (lab), capable of meeting the needs of all the individual
                    datacenters, but managed as a single data center
                </li>
                <li>Eliminate duplicate infrastructure</li>
                <li>Migrate remaining infrastructure to new data center</li>
            </ul>
            <h5 class="text-muted text-bold" style="font-size: 18px">MSSI APPROACH & RESULT:</h5>
            <ul>
                <li>MSSI worked with the multiple client teams to determine what was being done in their data center
                </li>
                <li>Inventoried the infrastructure in each data center</li>
                <li>Determined the issues, risks, and vulnerabilities of the existing infrastructure, and put mitigation
                    steps into place
                </li>
                <li>Designed a solution that consolidated as much infrastructure as possible into both physical and
                    virtual environment
                </li>
                <li>Implemented the consolidation of systems, and the migration of remaining infrastructure to the new
                    single lab
                </li>
                <li>Client was extremely satisfied with our design and implementation, we were asked to manage the data
                    center going forward, and continue to manage the data center today
                </li>
            </ul>
            <h5 class="text-muted text-bold" style="font-size: 18px">TECHNOLOGY:</h5>
            <p>Cisco UCS, Cisco Network Switches, Brocade fiber switch, EMC Enterprise Storage, Dell Servers, HP
                Servers</p>

        </div>
    </div>
</div>
<app-mssi-footer></app-mssi-footer>
