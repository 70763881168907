<app-mssi-header></app-mssi-header>
<div class="container-fluid ">
    <div class="row "></div>
    <div class="row mt-5"></div>
    <div class="row mt-5">
        <img class="img-fluid" src="../../../assets/img/office.jpg">
    </div>
    <div class="row">
        <div class="col-sm-1 col-12"></div>
        <div class="col-lg-12 col-12 text-muted">
            <p class="text-bold"><a href="#">Home</a>>>Office Setup</p>
            <p class="text-bold">Build</p>
            <p>MSSI will understand your exact needs for the need for an ODC (Offshore Development Center)
                Tactical needs, like cost-management, as well as Strategical requirements, like global presence, are
                amongst many used to craft requirements.
                A plan for Space Configuration, Resources (H/W, S/W), Hiring, Training is defined
                Various options to handle upfront costs are submitted for approval as well</p>
            <p class="text-bold mt-3">Operate</p>
            <p>MSSI Senior Management has extensive US and International experience
                All of Engineering, HR, Legal, and other support and management oversight provided
                Processes and Quality Systems put in place by MSSI
                Frequent Customer Satisfaction polls, as well as regular status meetings
                MSSI works on flexible pricing model</p>
            <p class="text-bold">Transfer</p>
            <p>Consistent with upfront plans and periodic status checks, clients can exercise the
                Option to take over the service team in a planned fashion
                MSSI can assist in transferring this ODC into your wholly owned subsidiary</p>
            <p>Interested in how MSSI Office Setup can benefit your business?</p>
            <p><a [routerLink]="['/contact-information']">Contact Us</a></p>
        </div>
    </div>
</div>
<div class="row mt-5"></div>
<app-mssi-footer></app-mssi-footer>
