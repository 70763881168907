import {Component} from "@angular/core";

@Component({
    selector: 'service-design',
    templateUrl: './service-design.component.html',
    styleUrls: ['./service-design.component.scss'],
})

export class ServiceDesignComponent {

}