import {Component} from "@angular/core";

@Component({
    selector: 'infrastructure',
    templateUrl: './infrastructure.component.html',
    styleUrls: ['./infrastructure.component.scss'],
})

export class InfrastructureComponent {

}