<app-mssi-header></app-mssi-header>
<div class="container-fluid ">
    <div class="row "></div>
    <div class="row mt-5"></div>
    <div class="row mt-5">
        <div id="carouselExampleAutoplaying" class="carousel slide" data-bs-ride="carousel">
            <div class="carousel-inner">
                <div class="carousel-item active">
                    <img src="../../../assets/img/spm1.png" class="d-block w-100" alt="..." style="width: 150px">
                </div>
                <div class="carousel-item">
                    <img src="../../../assets/img/spm2.png" class="d-block w-100" alt="..." style="width: 150px">
                </div>
                <div class="carousel-item">
                    <img src="../../../assets/img/spm3.png" class="d-block w-100" alt="..." style="width: 150px">
                </div>
            </div>
            <button class="carousel-control-prev" type="button" data-bs-target="#carouselExampleAutoplaying"
                    data-bs-slide="prev">
                <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                <span class="visually-hidden">Previous</span>
            </button>
            <button class="carousel-control-next" type="button" data-bs-target="#carouselExampleAutoplaying"
                    data-bs-slide="next">
                <span class="carousel-control-next-icon" aria-hidden="true"></span>
                <span class="visually-hidden">Next</span>
            </button>
        </div>
    </div>
    <div class="row">
        <div class="col-sm-1 col-12"></div>
        <div class="col-lg-11 col-12 mt-3">
            <p class="text-bold text-muted"><a href="#">Home</a>>>Program Management</p>
            <p class="text-muted">At MSSI, we work with our clients to plan and manage both
                projects and programs from start to finish, delivering them in a more cost effective manner while not
                sacrificing quality. We provide our clients transformation-focused services. Our service offerings
                include a formal yet tailored approach to client needs. We have the IT expertise, and certifications to
                meet your needs. Regardless of where your organization stands in terms of planning the work or
                executing, we offer services to help you succeed.
            </p>
            <p class="text-muted">At MSSI we utilize process, discipline, and leadership to
                break down functional silos, engage stakeholders, and ensure your initiatives are completed within
                budget, scope, and schedule.
                You can begin a project with us, from initiation to completion, or if your project is missing milestones
                or is lacking momentum, contact us and we will deploy project management consultants to get your project
                back on track.
            </p>
            <p class="text-muted">MSSI is flexible, we have the ability to utilize our own
                methodology, or we will adapt to your company’s methodology. In either case we will be able to deliver
                success.
            </p>
            <p class="text-muted" style="font-size: large">Interested in how MSSI Program Management can benefit your
                business?</p>
            <p><a [routerLink]="['/contact-information']">Contact Us</a></p>
        </div>
    </div>
</div>
<div class="row mt-5"></div>
<app-mssi-footer></app-mssi-footer>
