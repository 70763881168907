<nav class="navbar navbar-expand-lg bg-light fixed-top">
    <div class="container">
            <a href="#" class="navbar-brand ">
                <img class="img-fluid" height="50" width="70" src="../../../assets/img/MSSI-Logo.png">
            </a>
            <button type="button" class="navbar-toggler" data-bs-toggle="collapse" data-bs-target="#navbarCollapse">
                <span class="navbar-toggler-icon"><i class="fa fa-bars" aria-hidden="true"></i></span>
            </button>
        <div class="col-lg-3 ml-5"></div>
        <div class="collapse navbar-collapse ml-5" id="navbarCollapse">
            <div class="navbar-nav  text-muted text-bold">
                <li class="nav-item dropdown">
                    <a [routerLink]="['/home']"class="nav-link dropdown-toggle" href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                        Home
                    </a>
                    <ul class="dropdown-menu">
                        <li><a [routerLink]="['/solutions']"class="dropdown-item  text-muted" href="#" >Solutions</a></li>
                        <li><a [routerLink]="['/services']"class="dropdown-item  text-muted" href="#">Services</a></li>
                        <li><a [routerLink]="['/staffing']"class="dropdown-item  text-muted" href="#">Staffing</a></li>
                    </ul>
                </li>
                <a [routerLink]="['/about-us']"type="button" class="w3-bar-item w3-button w3-padding-16 nav-item nav-link">About Us</a>
                <a [routerLink]="['/careers']"type="button" class="nav-item nav-link w3-bar-item w3-button w3-padding-16">Careers</a>
                <li class="nav-item dropdown">
                    <a [routerLink]="['/recruiting']" class="nav-link dropdown-toggle" href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                        Recruiting
                    </a>
                    <ul class="dropdown-menu">
                        <li><a [routerLink]="['/current-jobs']" class="dropdown-item  text-muted" href="#">Current Jobs</a></li>
                        <li><a [routerLink]="['/submit-resume']" class="dropdown-item  text-muted" href="#">Submit Resume</a></li>
                    </ul>
                </li>
                <li class="nav-item dropdown">
                    <a class="nav-link dropdown-toggle" href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                        Contact Us
                    </a>
                    <ul class="dropdown-menu">
                        <li><a [routerLink]="['/locations']" class="dropdown-item  text-muted" href="#">Locations</a></li>
                        <li><a [routerLink]="['/contact-information']"class="dropdown-item  text-muted" href="#">Contact Information</a></li>
                    </ul>
                </li>
            </div>
        </div>

    </div>
</nav>

