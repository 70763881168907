import {Component} from "@angular/core";

@Component({
    selector: 'solutions',
    templateUrl: './solutions.component.html',
    styleUrls: ['./solutions.component.scss'],
})

export class SolutionsComponent {

}