<app-mssi-header></app-mssi-header>
<div class="container-fluid ">
    <div class="row "></div>
    <div class="row mt-5 "></div>
    <div class="row mt-5">
        <img class="img-fluid" src="../../../assets/img/staff.jpg">
    </div>
    <div class="row">
        <div class="col-sm-1 col-12"></div>
        <div class="col-lg-10 col-12 mt-3 text-muted">
            <p class="text-bold"><a href="#">Home</a>>>Staffing</p>
            <p>Whether you are looking to fill an open position, or looking for short/long term resources, MSSI can
                help. We pre-screen our candidates to make sure that you do not waste time on unqualified candidates.
                MSSI’s professional staffing team will work with your team to find the best talent. Our recruiters have
                in-depth market knowledge and industry experience in their chosen specialty areas and bring considerable
                technical and functional expertise to each assignment.
            </p>
            <p class="mt-4">The right team can make the difference between success and failure. In an increasingly
                competitive world, the margins for error have reduced and finding the right people at the right time is
                increasingly important. At MSSI, we understand the challenges your business faces and help fit skilled
                professionals into roles where they can deliver success.
            </p>
            <p>We provide staffing services on your terms and based on your needs. Whether your needs are full-time,
                contract, or contract-to-hire, we can help.</p>
            <p class="mt-4">Interested in how MSSI Staffing can benefit your business?</p>
            <p><a [routerLink]="['/contact-information']">Contact Us</a></p>
        </div>
        <div class="col-sm-1 col-12"></div>
    </div>
</div>
<div class="row mt-5"></div>
<app-mssi-footer></app-mssi-footer>
