import {Component} from "@angular/core";

@Component({
    selector: 'recruiting',
    templateUrl: './recruiting.component.html',
    styleUrls: ['./recruiting.component.scss'],
})

export class RecruitingComponent {

}