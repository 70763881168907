<app-mssi-header></app-mssi-header>
<div class="container-fluid ">
    <div class="row "></div>
    <div class="row mt-5"></div>
    <div class="row mt-5">
        <img class="img-fluid" src="../../../assets/img/about.jpg">
    </div>
    <div class="row">
        <div class="col-sm-1 col-12"></div>
        <div class="col-lg-10 col-12 mt-3  text-muted">
            <p>At MSSI, we understand that organizations are confronted with the challenge of developing cutting-edge
                technologies and implementing business applications to be able to withstand demands of their customers.
            </p>
            <p class="mt-2">MSSI is a leading service organization providing technology, management, and compliance
                consulting as well as staffing and outsourcing services. Our areas of expertise include all areas of
                infrastructure, architectural assessments, infrastructure assessments, risk assessments, project &
                program management, application development, and offshore office setup.
            </p>
            <p>Our real strength comes from combining these services to address the specific business and technology
                needs of our clients all in one place. Our people possess a wide range of talents and a demonstrated
                track record of success.
            </p>
            <p>Our clients, many of which are Fortune 1000 companies in various industries, rely on our expertise,
                objective insight and ability to deliver innovative, customized answers to even the toughest technology
                and business challenges.
            </p>
            <p>Be it building a custom or packaged application, implementing state of the art infrastructure or
                providing 24/7 outsourced support to meet your unique requirements, Team-MSSI does it all.
            </p>
            <p class="mt-2">Interested in MSSI ?</p>
            <p><a [routerLink]="['/contact-information']">Contact Us</a></p>
        </div>
        <div class="col-sm-1 col-12"></div>
    </div>
</div>
<div class="row mt-5"></div>
<app-mssi-footer></app-mssi-footer>
