<app-mssi-header></app-mssi-header>
<div class="container-fluid ">
    <div class="row "></div>
    <div class="row mt-5"></div>
    <div class="row mt-5">
        <div id="carouselExampleAutoplaying" class="carousel slide" data-bs-ride="carousel">
            <div class="carousel-inner">
                <div class="carousel-item active">
                    <img src="../../../assets/img/ss1.jpg" class="d-block w-100" alt="..." style="width: 150px">
                </div>
                <div class="carousel-item">
                    <img src="../../../assets/img/ss2.jpg" class="d-block w-100" alt="..." style="width: 150px">
                </div>
                <div class="carousel-item">
                    <img src="../../../assets/img/ss3.jpg" class="d-block w-100" alt="..." style="width: 150px">
                </div>
            </div>
            <button class="carousel-control-prev" type="button" data-bs-target="#carouselExampleAutoplaying"
                    data-bs-slide="prev">
                <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                <span class="visually-hidden">Previous</span>
            </button>
            <button class="carousel-control-next" type="button" data-bs-target="#carouselExampleAutoplaying"
                    data-bs-slide="next">
                <span class="carousel-control-next-icon" aria-hidden="true"></span>
                <span class="visually-hidden">Next</span>
            </button>
        </div>
    </div>
    <div class="row">
        <div class="col-sm-1 col-12"></div>
        <div class="col-lg-11 col-12 mt-3 text-muted">
            <p class="text-bold"><a href="#">Home</a>>>24×7 Support</p>
            <p>MSSI’s production support specialists investigate and work to resolve all issues allowing your company to
                experience the benefits of its technology investment.
            </p>
            <p>MSSI offers comprehensive support services in all areas of IT Operations. Clients benefit from our
                support services, by freeing up internal resources from tedious day to day operational support tasks,
                thus enabling their own high performing strategic professionals to focus on the highest priority tasks,
                and projects.
            </p>
            <p>Unlike other IT support service companies, MSSI is not looking to replace your current IT operations
                team, but rather to enhance it. MSSI will analyze your companies current Operational support team, and
                look for opportunities to improve efficiencies, while expanding overall support offerings.
            </p>
            <p>MSSI offers 24X7X365 support coverage in all areas. Our teams have years of experience in supporting
                multiple areas of IT operations. MSSI support team have multiple members with certifications in their
                areas of expertise, allowing you and your company to feel confident in our team’s ability to benefit
                your organization.
            </p>
            <p>Interested in how MSSI 24x7 Services can benefit your business?</p>
            <p><a [routerLink]="['/contact-information']">Contact Us</a></p>
        </div>
    </div>
</div>
<div class="row mt-5"></div>
<app-mssi-footer></app-mssi-footer>
