import {Component, HostListener, OnInit, ViewEncapsulation} from "@angular/core";
import {Router} from "@angular/router";
import {DateTime} from 'luxon';
import SwiperCore, {Navigation, Pagination, Swiper, EffectFade, SwiperOptions} from 'swiper';
import {FormBuilder, UntypedFormControl, UntypedFormGroup, Validators} from "@angular/forms";

SwiperCore.use([Navigation, Pagination]);
SwiperCore.use([EffectFade]);
import AOS from "aos";
import {AppService} from "@services/app.service";
import {Subject, takeUntil} from 'rxjs';
import {data} from "browserslist";
import {ToastrService} from "ngx-toastr";


@Component({
    selector: 'website-vh',
    templateUrl: './website.component.html',
    styleUrls: ['./website.component.scss'],
    encapsulation: ViewEncapsulation.None
})


export class WebsiteComponent implements OnInit {
    destroy$: Subject<boolean> = new Subject<boolean>();
    public currentYear: string = DateTime.now().toFormat('y');
    showContent = false;
    showContentTwo = false;
    showContentThree = false;
    showContentFour = false;
    staffCount: number = 0;
    roomsCount: number = 0;
    happyClients: number = 0;
    currentDate: string;

    constructor(private router: Router, private appService: AppService, private toaster: ToastrService) {
        this.setCurrentDate();
    }

    ngOnInit() {
        AOS.init();
    }

    appointmentForm = new UntypedFormGroup({
        patientFirstName: new UntypedFormControl("", [Validators.required, Validators.nullValidator]),
        patientLastName: new UntypedFormControl("", [Validators.required, Validators.nullValidator]),
        phoneNo: new UntypedFormControl("", [Validators.required, Validators.nullValidator, Validators.pattern(/^\d{10}$/)]),
        patientName: new UntypedFormControl("", [Validators.required, Validators.nullValidator]),
        appointmentDate: new UntypedFormControl("", [Validators.required, Validators.nullValidator]),
        emailId: new UntypedFormControl(null, [Validators.required, Validators.nullValidator, Validators.pattern("^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$")]),
    })

    appointmentFormErrors = {
        patientFirstName: '',
        patientLastName: '',
        phoneNo: '',
        patientName: '',
        appointmentDate: '',
        emailId: '',
    }

    onSubmit() {
        this.formValidation();
    }

    formValidation() {
        this.appointmentFormErrors.patientFirstName = ""
        this.appointmentFormErrors.patientLastName = ""
        this.appointmentFormErrors.patientName = ""
        this.appointmentFormErrors.phoneNo = ""
        this.appointmentFormErrors.emailId = ""
        this.appointmentFormErrors.appointmentDate = ""
        let hasError = false;

        if (this.appointmentForm.get('patientFirstName').invalid) {
            this.appointmentFormErrors.patientFirstName = "First Name is required"
            hasError = true;
        }

        if (this.appointmentForm.get('patientLastName').invalid) {
            this.appointmentFormErrors.patientLastName = "Last Name is required"
            hasError = true;
        }

        if (this.appointmentForm.get("phoneNo")?.invalid) {
            if (this.appointmentForm.get('phoneNo').errors.required)
                this.appointmentFormErrors.phoneNo = "Phone Number is required"
            hasError = true
            if (this.appointmentForm.get('phoneNo').errors.pattern) {
                this.appointmentFormErrors.phoneNo = "Please Enter 10 Digit Valid Phone Number"
                hasError = true;
            }
        }

        if (this.appointmentForm.get("emailId")?.invalid) {
            if (this.appointmentForm.get('emailId').errors.required)
                this.appointmentFormErrors.emailId = "Email ID is Required";
            hasError = true
            if (this.appointmentForm.get('emailId').errors.pattern) {
                this.appointmentFormErrors.emailId = "Enter Valid Email.(Ex:example@gmail.com)";
                hasError = true;
            }
        }

        if (this.appointmentForm.get('appointmentDate').invalid) {
            this.appointmentFormErrors.appointmentDate = "Appointment Date is required"
            hasError = true;
        }

        if (this.appointmentForm.get('patientName').invalid) {
            this.appointmentFormErrors.patientName = "Patient Name is required"
            hasError = true;
        }

        if (!hasError) {
            /*this.patientAppointmentDetails();*/
        }
    }

    //Only Enter Number Input box Event
    OnlyNumbers($event) {
        let regex: RegExp = new RegExp(/^[0-9]{1,}$/g);
        let specialKeys: Array<string> = ['Backspace', 'Tab', 'End', 'Home', 'ArrowRight', 'ArrowLeft'];
        if (specialKeys.indexOf($event.key) !== -1) {
            return;
        } else {
            if (regex.test($event.key)) {
                return true;
            } else {
                return false;
            }
        }
    }

    setCurrentDate(): void {
        const today = new Date();
        const year = today.getFullYear();
        const month = (today.getMonth() + 1).toString().padStart(2, '0');
        const day = today.getDate().toString().padStart(2, '0');

        this.currentDate = `${year}-${month}-${day}`;
    }

    @HostListener('click', ['$event.target'])
    onClick(target: any) {
        if (target.classList.contains('nav-link') && target.innerText.trim().toLowerCase() === 'home') {
            this.scrollToHome();
        }
        if (target.classList.contains('nav-link') && target.innerText.trim().toLowerCase() === 'about') {
            this.scrollToAbout();
        }
        if (target.classList.contains('nav-link') && target.innerText.trim().toLowerCase() === 'appointment') {
            this.scrollToAppointment();
        }
        if (target.classList.contains('nav-link') && target.innerText.trim().toLowerCase() === 'department') {
            this.scrollToDepartment();
        }
        if (target.classList.contains('nav-link') && target.innerText.trim().toLowerCase() === 'doctors') {
            this.scrollToDoctors();
        }
        if (target.classList.contains('nav-link') && target.innerText.trim().toLowerCase() === 'services') {
            this.scrollToOurServices();
        }
        if (target.classList.contains('nav-link') && target.innerText.trim().toLowerCase() === 'contacts') {
            this.scrollToContacts();
        }
    }


    private scrollToHome() {
        const homeSection = document.getElementById('home-section');

        if (homeSection) {
            const yOffset = homeSection.offsetTop;
            window.scrollTo({top: yOffset, behavior: 'smooth'});
        }
    }

    private scrollToAbout() {
        const aboutSection = document.getElementById('about-section');

        if (aboutSection) {
            const yOffset = aboutSection.offsetTop;
            window.scrollTo({top: yOffset, behavior: 'smooth'});
        }
    }

    private scrollToAppointment() {
        const appointmentSection = document.getElementById('appointment-section');

        if (appointmentSection) {
            const yOffset = appointmentSection.offsetTop;
            window.scrollTo({top: yOffset, behavior: 'smooth'});
        }
    }

    private scrollToDepartment() {
        const departmentSection = document.getElementById('department-section');

        if (departmentSection) {
            const yOffset = departmentSection.offsetTop;
            window.scrollTo({top: yOffset, behavior: 'smooth'});
        }
    }

    private scrollToDoctors() {
        const doctorSection = document.getElementById('doctors-section');

        if (doctorSection) {
            const yOffset = doctorSection.offsetTop;
            window.scrollTo({top: yOffset, behavior: 'smooth'});
        }
    }

    private scrollToOurServices() {
        const serviceSection = document.getElementById('services-section');

        if (serviceSection) {
            const yOffset = serviceSection.offsetTop;
            window.scrollTo({top: yOffset, behavior: 'smooth'});
        }
    }

    private scrollToContacts() {
        const contactSection = document.getElementById('contact-section');

        if (contactSection) {
            const yOffset = contactSection.offsetTop;
            window.scrollTo({top: yOffset, behavior: 'smooth'});
        }
    }

    stopStaffCountStop: any = setInterval(() => {
        this.staffCount++;
        if (this.staffCount == 20) {
            clearInterval(this.stopStaffCountStop);
        }
    }, 100)

    roomCountStop: any = setInterval(() => {
        this.roomsCount++;
        if (this.roomsCount == 10) {
            clearInterval(this.roomCountStop);
        }
    }, 100)

    happyClientsCountStop: any = setInterval(() => {
        this.happyClients++;
        if (this.happyClients == 1000) {
            clearInterval(this.happyClientsCountStop);
        }
    }, 10)

    @HostListener('window:scroll', [])
    onScroll() {
        // Adjust this threshold as needed for when you want the content to appear.
        // @ts-ignore
        if (window.scrollY) {
            this.showContent = true;
        }
        if (window.scrollY) {
            this.showContentTwo = true;
        }
        if (window.scrollY) {
            this.showContentThree = true;
        }
        if (window.scrollY) {
            this.showContentFour = true;
        }
    }

    config: SwiperOptions = {
        pagination: {el: '.swiper-pagination', clickable: true},
        allowTouchMove: true,
        breakpoints: {
            1024: {
                slidesPerView: 4
            },
            500: {
                slidesPerView: 2
            },
            400: {
                slidesPerView: 1
            },
            300: {
                slidesPerView: 1
            }
        },

    };


    /*patientAppointmentDetails() {
        this.appService.savePatientDetails(this.appointmentForm.value)
            .pipe(takeUntil(this.destroy$))
            .subscribe((data) => {
                console.log(data)
                this.toaster.success("Your Appointment is Booked, Thank You");
                this.appointmentForm.reset();
            }, (error: any) => {
                this.toaster.error("Please try again. Something went wrong. We couldn't book that appointment. Please reload the page and try again.")
                console.log("Something went wrong");
            });
    }*/
}
