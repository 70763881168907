import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {MainComponent} from '@modules/main/main.component';
import {BlankComponent} from '@pages/blank/blank.component';
import {LoginComponent} from '@modules/login/login.component';
import {ProfileComponent} from '@pages/profile/profile.component';
import {RegisterComponent} from '@modules/register/register.component';
import {DashboardComponent} from '@pages/dashboard/dashboard.component';
import {AuthGuard} from '@guards/auth.guard';
import {NonAuthGuard} from '@guards/non-auth.guard';
import {ForgotPasswordComponent} from '@modules/forgot-password/forgot-password.component';
import {RecoverPasswordComponent} from '@modules/recover-password/recover-password.component';
import {SubMenuComponent} from '@pages/main-menu/sub-menu/sub-menu.component';
import {WebsiteComponent} from "@modules/website/website.component";
import {TermsComponent} from "@modules/terms-and-conditions/terms.component";
import {PrivacyPolicyComponent} from "@modules/privacy-policy/privacy-policy.component";
import {HomeComponent} from "@modules/mssi/home-main/home/home.component";
import {ServicesComponent} from "@modules/mssi/home-main/services/services.component";
import {ServiceDesignComponent} from "@modules/mssi/home-main/services/service-design/service-design.component";
import {ServiceProgramComponent} from "@modules/mssi/home-main/services/service-program/service-program.component";
import {ServiceSupportComponent} from "@modules/mssi/home-main/services/service-support/service-support.component";
import {SolutionsComponent} from "@modules/mssi/home-main/solutions/solutions.component";
import {AppDevelopmentComponent} from "@modules/mssi/home-main/solutions/app-development/app-development.component";
import {ArchitectureDesignComponent} from "@modules/mssi/home-main/solutions/architecture-design/architecture-design.component";
import {OfficeSetupComponent} from "@modules/mssi/home-main/solutions/office-setup/office-setup.component";
import {OngoingSupportComponent} from "@modules/mssi/home-main/solutions/ongoing-support/ongoing-support.component";
import {RiskComponent} from "@modules/mssi/home-main/solutions/risk/risk.component";
import {StaffingComponent} from "@modules/mssi/home-main/staffing/staffing.component";
import {RecruitingComponent} from "@modules/mssi/recruiting/recruiting.component";
import {CurrentJobsComponent} from "@modules/mssi/recruiting/current-jobs/current-jobs.component";
import {SubmitResumeComponent} from "@modules/mssi/recruiting/submit-resume/submit-resume.component";
import {InfrastructureComponent} from "@modules/mssi/home-main/solutions/infrastructure/infrastructure.component";
import {AboutComponent} from "@modules/mssi/about-us/about.component";
import {CareersComponent} from "@modules/mssi/careers/careers.component";
import {ContactInformationComponent} from "@modules/mssi/contact-us/contact-information/contact-information.component";
import {LocationsComponent} from "@modules/mssi/contact-us/locations/locations.component";
import {Case1Component} from "@modules/mssi/case-study/case1/case1.component";
import {Case2Component} from "@modules/mssi/case-study/case2/case2.component";
import {Case4Component} from "@modules/mssi/case-study/case4/case4.component";
import {Case5Component} from "@modules/mssi/case-study/case5/case5.component";
import {Case6Component} from "@modules/mssi/case-study/case6/case6.component";
import {Case7Component} from "@modules/mssi/case-study/case7/case7.component";
import {Case3Component} from "@modules/mssi/case-study/case3/case3.component";

const routes: Routes = [
  {
    path: 'main',
    component: MainComponent,
    canActivate: [AuthGuard],
    canActivateChild: [AuthGuard],
    children: [
      {
        path: 'profile',
        component: ProfileComponent
      },
      {
        path: 'blank',
        component: BlankComponent
      },
      {
        path: 'sub-menu-1',
        component: SubMenuComponent
      },
      {
        path: 'sub-menu-2',
        component: BlankComponent
      },
      {
        path: 'dashboard',
        component: DashboardComponent
      }
    ]
  },
  {
    path: '',
    component: HomeComponent,
  },
  {
    path: 'contact-information',
    component: ContactInformationComponent,
  },
  {
    path: 'case1',
    component: Case1Component,
  },
  {
    path: 'case2',
    component: Case2Component,
  },
  {
    path: 'case4',
    component: Case4Component,
  },
  {
    path: 'case5',
    component: Case5Component,
  },
  {
    path: 'case6',
    component: Case6Component,
  },
  {
    path: 'case7',
    component: Case7Component,
  },
  {
    path: 'case3',
    component: Case3Component,
  },
  {
    path: 'locations',
    component: LocationsComponent,
  },
  {
    path: 'services',
    component: ServicesComponent,
  },
  {
    path: 'service-design',
    component: ServiceDesignComponent,
  },
  {
    path: 'about-us',
    component: AboutComponent,
  },
  {
    path: 'careers',
    component: CareersComponent,
  },
  {
    path: 'current-jobs',
    component: CurrentJobsComponent,
  },
  {
    path: 'service-program',
    component: ServiceProgramComponent,
  },
  {
    path: 'service-support',
    component: ServiceSupportComponent,
  },
  {
    path: 'solutions',
    component: SolutionsComponent,
  },
  {
    path: 'app-development',
    component: AppDevelopmentComponent,
  },
  {
    path: 'architecture-design',
    component: ArchitectureDesignComponent,
  },
  {
    path: 'infrastructure',
    component: InfrastructureComponent,
  },
  {
    path: 'office-setup',
    component: OfficeSetupComponent,
  },
  {
    path: 'ongoing-support',
    component: OngoingSupportComponent,
  },
  {
    path: 'risk',
    component: RiskComponent,
  },
  {
    path: 'staffing',
    component: StaffingComponent,
  },
  {
    path: 'recruiting',
    component: RecruitingComponent,
  },
  {
    path: 'current-jobs',
    component: CurrentJobsComponent,
  },
  {
    path: 'submit-resume',
    component: SubmitResumeComponent,
  },
  {
    path: 'terms',
    component: TermsComponent
  },
  {
    path: 'privacy',
    component: PrivacyPolicyComponent
  },
  {
    path: 'login',
    component: LoginComponent,
    canActivate: [NonAuthGuard]
  },
  {
    path: 'register',
    component: RegisterComponent,
    canActivate: [NonAuthGuard]
  },
  {
    path: 'forgot-password',
    component: ForgotPasswordComponent,
    canActivate: [NonAuthGuard]
  },
  {
    path: 'recover-password',
    component: RecoverPasswordComponent,
    canActivate: [NonAuthGuard]
  },
  {path: '**', redirectTo: ''}
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
