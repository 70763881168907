import {Component} from "@angular/core";

@Component({
    selector: 'office-setup',
    templateUrl: './office-setup.component.html',
    styleUrls: ['./office-setup.component.scss'],
})

export class OfficeSetupComponent {

}