<app-mssi-header></app-mssi-header>
<div class="container-fluid mt-5">
    <div class="row mt-5"></div>
    <div class="row mt-5"></div>
    <div class="row">
        <div class="col-sm-1 col-12"></div>
        <div class="col-lg-11 col-12 mt-3">
            <p class="text-muted text-bold" style="font-size: 15px"><a [routerLink]="['/home']">Home</a> >>Case Study>>
                Legacy Systems Assessment and Ongoing Support</p>
            <p class="font-italic text-bold text-muted">CLIENT: A worldwide manufacturer/distributer of power and hand
                tools</p>
            <h5 class="mt-3 text-bold text-muted" style="font-size: 18px">BUSINESS PROBLEM:</h5>
            <p>Our client acquired a company with some business critical systems that were severely out of date, both
                hardware and software. The company had it in their plan to integrate this system with their own but not
                for one and half years
            </p>
            <h5 class="text-muted text-bold" style="font-size: 18px">SOLUTION:</h5>
            <ul>
                <li>Assess the current infrastructure</li>
                <li>Provide support for the existing environment</li>
                <li>Develop a low cost replacement for the system to be utilized until the migration to the final
                    system
                </li>
            </ul>
            <h5 class="text-muted text-bold" style="font-size: 18px">MSSI APPROACH & RESULT:</h5>
            <ul>
                <li>MSSI sent in an architect, engineer, and project manager to assess the current system, and
                    environment
                </li>
                <li>Analyzed the infrastructure, determined the highest risks, began working on solution that would
                    address highest risks first
                </li>
                <li>MSSI worked with the client to determine the appropriate level of support needed, and implemented an
                    off-shore team to provide the need support
                </li>
                <li>Designed a very inexpensive solution allowing the infrastructure to be upgraded to current standards
                    until the integration was completed
                </li>
            </ul>
            <h5 class="text-muted text-bold" style="font-size: 18px">TECHNOLOGY:</h5>
            <p> Novel Netware, Oracle, OS2 Warp, VMWare, SQL</p>
        </div>
    </div>
</div>
<div class="row mt-5"></div>
<app-mssi-footer></app-mssi-footer>
