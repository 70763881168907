<app-mssi-header></app-mssi-header>
<div class="container-fluid ">
    <div class="row "></div>
    <div class="row mt-5"></div>
    <div class="row mt-5">
        <img class="img-fluid" src="../../../assets/img/submit.png">
    </div>
    <div class="row">
        <div class="col-sm-2 col-12"></div>
        <div class="col-lg-8 col-12">
            <form class="text-muted mt-4" [formGroup]="contactUs" (ngSubmit)="onSubmit()">
                <div class="row">
                    <div class="col-lg-6 col-12 col-md-12">
                        <label for="name" class="form-label">Your Name (required)</label>
                        <input type="text" class="form-control" id="name" formControlName="name">
                        <span *ngIf="contactUsError.name"
                              class="text-danger">{{contactUsError.name}}</span>
                    </div>
                    <div class="col-lg-6 col-12 col-md-12">
                        <label for="email" class="form-label">Your Email (required)</label>
                        <input type="email" class="form-control" id="email" formControlName="emailId">
                        <span *ngIf="contactUsError.emailId"
                              class="text-danger">{{contactUsError.emailId}}</span>
                    </div>
                </div>
                <div class="row mt-3">
                    <div class="col-lg-6 col-12 col-md-12">
                        <label for="num" class="form-label">Call Back Number</label>
                        <input type="text" class="form-control" id="num" formControlName="phoneNumber" (keypress)="numberOnly($event)" maxlength="10" minlength="10">
                        <span *ngIf="contactUsError.phoneNumber"
                              class="text-danger">{{contactUsError.phoneNumber}}</span>
                    </div>
                    <div class="col-lg-6 col-12 col-md-12">
                        <label for="sub" class="form-label">Subject</label>
                        <input type="text" class="form-control" id="sub" formControlName="subject">
                        <span *ngIf="contactUsError.subject"
                              class="text-danger">{{contactUsError.subject}</span>
                    </div>
                </div>
                <div class="row mt-3">
                    <div class="col-lg-6 col-12 col-md-12">
                        <label for="msg" class="form-label">Your Message</label>
                        <input type="text" class="form-control" id="msg" formControlName="message">
                        <span *ngIf="contactUsError.message"
                              class="text-danger">{{contactUsError.message}}</span>
                    </div>
                    <div class="col-lg-6 col-12 col-md-12">
                        <label for="formFile" class="form-label">Resume (required)</label>
                        <input class="form-control" type="file" id="formFile" (change)="onFileSelected($event)" formControlName="resumeFile" accept=".pdf, .doc, .docx">
                        <span *ngIf="contactUsError.resumeFile"
                              class="text-danger">{{contactUsError.resumeFile}}</span>
                    </div>
                </div>
                <div class="row mt-3">
                    <div class="col-lg-12 text-center">
                        <button type="submit" class="btn btn-primary btn-lg mt-4">Send</button>
                    </div>
                </div>
            </form>
        </div>
    </div>
</div>
<div class="row mt-5"></div>
<div class="row mt-3"></div>
<app-mssi-footer></app-mssi-footer>
<ngx-spinner  size="large">
    <span class="loader"></span>
</ngx-spinner>
