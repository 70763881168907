import {Component} from "@angular/core";

@Component({
    selector: 'staffing',
    templateUrl: './staffing.component.html',
    styleUrls: ['./staffing.component.scss'],
})

export class StaffingComponent {

}