<app-mssi-header></app-mssi-header>
<div class="container-fluid">
    <div class="row mt-5">
        <img class="img-fluid mt-5" src="../../../assets/img/ci.png">
    </div>
    <div class="row">
        <div class="col-sm-1 col-12"></div>
        <div class="col-lg-5 col-12 mt-3 ">
            <h5 class="text-muted">Call us at: <a href="#">508-918-0470</a></h5>
            <h5 class="text-muted">Write us at: <a href="#">contact-mssi@MSServicesInc.com</a></h5>
            <form class="text-muted mt-4" [formGroup]="contactUs" (ngSubmit)="onSubmit()">
                <div class="row">
                    <div class="col-lg-6 col-12 col-md-12">
                        <label for="name" class="form-label">Your Name (required)</label>
                        <input type="text" class="form-control" id="name" formControlName="name">
                        <span *ngIf="contactUsError.name"
                              class="text-danger">{{contactUsError.name}}</span>
                    </div>
                    <div class="col-lg-6 col-12 col-md-12">
                        <label for="email" class="form-label">Your Email (required)</label>
                        <input type="email" class="form-control" id="email" formControlName="emailId">
                        <span *ngIf="contactUsError.emailId"
                              class="text-danger">{{contactUsError.emailId}}</span>
                    </div>
                </div>
                <div class="row mt-3">
                    <div class="col-lg-6 col-12 col-md-12">
                        <label for="num" class="form-label">Call Back Number</label>
                        <input type="text" class="form-control" id="num" formControlName="phoneNumber" (keypress)="numberOnly($event)" maxlength="10" minlength="10">
                        <span *ngIf="contactUsError.phoneNumber"
                              class="text-danger">{{contactUsError.phoneNumber}}</span>
                    </div>
                    <div class="col-lg-6 col-12 col-md-12">
                        <label for="sub" class="form-label">Subject</label>
                        <input type="text" class="form-control" id="sub" formControlName="subject">
                        <span *ngIf="contactUsError.subject"
                              class="text-danger">{{contactUsError.subject}</span>
                    </div>
                </div>
                <div class="row mt-3">
                    <div class="col-lg-6 col-12 col-md-12">
                        <label for="msg" class="form-label">Your Message</label>
                        <input type="text" class="form-control" id="msg" formControlName="message">
                        <span *ngIf="contactUsError.message"
                              class="text-danger">{{contactUsError.message}}</span>
                    </div>
                    <div class="col-lg-6 col-12 col-md-12">
                        <label for="formFile" class="form-label">Resume (required)</label>
                        <input class="form-control" type="file" id="formFile" (change)="onFileSelected($event)" formControlName="resumeFile" accept=".pdf, .doc, .docx">
                        <span *ngIf="contactUsError.resumeFile"
                              class="text-danger">{{contactUsError.resumeFile}}</span>
                    </div>
                </div>
                <div class="row mt-3">
                    <div class="col-lg-12 text-center">
                        <button type="submit" class="btn btn-primary btn-lg mt-4">Send</button>
                    </div>
                </div>
            </form>
        </div>
        <div class="col-lg-6 col-12 mt-3">
            <div class="responsive-map">
                <iframe class="embed-responsive-item" src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d188966.05207467865!2d-71.610298!3d42.26583!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x89e40aee339559d9%3A0x16efac67d509ebaa!2sWestborough%2C%20MA%2C%20USA!5e0!3m2!1sen!2sin!4v1701677614067!5m2!1sen!2sin" width="600" height="450" frameborder="0" style="border:0" allowfullscreen></iframe>
            </div>

        </div>
    </div>
</div>
<div class="row mt-5"></div>
<div class="row mt-3"></div>
<app-mssi-footer></app-mssi-footer>
<!--<div class="footer">-->
<!--    <p class="mt-2" style="font-size: 12px">@ 2020 <a href="http://msservicesinc.com/"> Mass System Services Inc</a></p>-->
<!--</div>-->
<ngx-spinner  size="large">
    <span class="loader"></span>
</ngx-spinner>