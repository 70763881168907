<app-mssi-header></app-mssi-header>
<div class="container-fluid mt-5">
    <div class="row mt-5"></div>
    <div class="row mt-5"></div>
    <div class="row">
        <div class="col-sm-1 col-12"></div>
        <div class="col-lg-11 col-12 mt-3">
            <p class="text-muted text-bold" style="font-size: 15px"><a [routerLink]="['/home']">Home</a> >>Case
                Study>>Mobile Application Development</p>
            <p class="font-italic text-muted text-bold">CLIENT: A fortune 500 IT company with both hardware and software
                products used worldwide</p>
            <h5 class="mt-3 text-bold text-muted" style="font-size: 18px">BUSINESS PROBLEM:</h5>
            <p>The client does not have a convenient way for pre-sales, post-sales, and field personnel to enter
                information regarding customer interactions, which had an impact on efficiency, customer data depth, and
                up to the minute information.
            </p>
            <h5 class="text-muted text-bold" style="font-size: 18px">SOLUTION:</h5>
            <ul>
                <li>Design a simple to use mobile app that would allow a user to enter customer feedback and information
                    into a pre-defined format, with ‘free text’ fields that will allow the user to enter any specific
                    information needed, if the pre-filled pick list is not adequate
                </li>
                <li>Create a simple interface for the end user</li>
                <li>Create an app that is secure</li>
            </ul>
            <h5 class="text-muted text-bold" style="font-size: 18px">MSSI APPROACH & RESULT:</h5>
            <ul>
                <li>MSSI developed an overall database design to meet the needs of the client</li>
                <li>MSSI designed the interface, and the flow of the interface utilizing feedback from multiple future
                    end users to ensure, and ease of use, and the applications acceptance by the target user group
                </li>
                <li>MSSI utilized existing security protocols to ensure the apps security, while allowing background
                    data submissions to be performed as the user used the mobile device for other activities
                </li>
            </ul>
            <h5 class="text-muted text-bold" style="font-size: 18px">TECHNOLOGY:</h5>
            <p> iOS, SQL, Appcelerator, INTEl XDK, JavaScript, Android SDK, Apple's XCode, HTML5, INTEL XDK, JSON Data
                Transfer, BaaS framework </p>
        </div>
    </div>
</div>
<div class="row mt-5"></div>
<app-mssi-footer></app-mssi-footer>
