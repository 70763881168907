import {Component} from "@angular/core";

@Component({
    selector: 'case2',
    templateUrl: './case2.component.html',
    styleUrls: ['./case2.component.scss'],
})

export class Case2Component {

}
