<app-mssi-header></app-mssi-header>
<div class="container-fluid ">
    <div class="row "></div>
    <div class="row mt-5"></div>
    <div class="row mt-5">
        <div id="carouselExampleAutoplaying" class="carousel slide" data-bs-ride="carousel">
            <div class="carousel-inner">
                <div class="carousel-item active">
                    <img src="../../../assets/img/ser1.png" class="d-block w-100" alt="..." style="width: 150px">
                </div>
                <div class="carousel-item">
                    <img src="../../../assets/img/ser2.png" class="d-block w-100" alt="..." style="width: 150px">
                </div>
                <div class="carousel-item">
                    <img src="../../../assets/img/ser3.png" class="d-block w-100" alt="..." style="width: 150px">
                </div>
                <div class="carousel-item">
                    <img src="../../../assets/img/ser4.png" class="d-block w-100" alt="..." style="width: 150px">
                </div>
            </div>
            <button class="carousel-control-prev" type="button" data-bs-target="#carouselExampleAutoplaying"
                    data-bs-slide="prev">
                <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                <span class="visually-hidden">Previous</span>
            </button>
            <button class="carousel-control-next" type="button" data-bs-target="#carouselExampleAutoplaying"
                    data-bs-slide="next">
                <span class="carousel-control-next-icon" aria-hidden="true"></span>
                <span class="visually-hidden">Next</span>
            </button>
        </div>
    </div>
    <div class="row">
        <div class="col-sm-1 col-12"></div>
        <div class="col-lg-6 col-12 mt-3 text-muted">
            <p class="text-bold"><a href="#">Home</a>>>Services</p>
            <p class="mt-3">MSSI’s services are a cost effective alternative to conventional support models in order to
                handle surging demands, increased growth, ever expanding technology, and short or long term projects.
            </p>
            <p class="mt-3">After meeting with you to determine your company’s challenges, our professionals identify
                the type of subject-matter experts that will be useful in meeting your goals. We then recommend
                individuals or teams from our staff with the necessary qualifications, and you pick the person(s) who
                best fit your company. MSSI consultants can partner with your existing team or work independently,
                on-site or remotely, for any length of time.
            </p>
            <p>Interested in how MSSI Services can benefit your business?</p>
            <p><a [routerLink]="['/contact-information']">Contact Us</a></p>
        </div>
        <div class="col-lg-4 col-12 mt-3 ">
            <a [routerLink]="['/service-support']"><img class="img-fluid" src="../../../assets/img/ss1.jpg"
                                                        alt="Service-Support"> </a>
            <br>
            <a [routerLink]="['/service-program']"><img class="img-fluid mt-5" src="../../../assets/img/spm1.png"
                                                        alt="Service-Programming-Management"></a>
            <br>
            <a [routerLink]="['/service-design']"><img class="img-fluid mt-5" src="../../../assets/img/architecture.jpg"
                                                       alt="Service-Design-Architecture"></a>
        </div>
        <div class="col-sm-1 col-12"></div>
    </div>

</div>
<div class="row mt-5"></div>
<div class="row mt-5"></div>
<app-mssi-footer></app-mssi-footer>
