import {Component} from "@angular/core";

@Component({
    selector: 'service-support',
    templateUrl: './service-support.component.html',
    styleUrls: ['./service-support.component.scss'],
})

export class ServiceSupportComponent {

}