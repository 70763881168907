<app-mssi-header></app-mssi-header>
<div class="container-fluid mt-5">
    <div class="row mt-5"></div>
    <div class="row mt-5"></div>
    <div class="row">
        <div class="col-sm-1 col-12"></div>
        <div class="col-lg-11 col-12 mt-3">
            <p class="text-muted text-bold"><a [routerLink]="['/home']">Home</a> >>Case Study:Implementation of an
                Intercompany Consolidated Financial Management System</p>
            <p class="font-italic text-bold text-muted">CLIENT: A worldwide manufacturer/distributer of power and hand
                tools</p>
            <h5 class="mt-3 text-bold text-muted" style="font-size: 18px">BUSINESS PROBLEM:</h5>
            <p>The company had multiple financial software products, so having a single consolidated complete reporting
                system was required.</p>
            <h5 class="text-muted text-bold" style="font-size: 18px">SOLUTION:</h5>
            <ul>
                <li>Scalable Architecture - Architect an infrastructure capable of meeting the needs of the business
                    over the long term as different business units were migrated to it
                </li>
                <li>Maximum Availability Architecture –Created a robust High Availability and Disaster Recovery
                    solutions for the new environment
                </li>
                <li>Designed a cost effective infrastructure solution</li>
                <li>Determined resourcing needs for this project, as well as ongoing support</li>
                <li>Worked with existing infrastructure and application client teams to architect solutions to meet
                    their needs as well as the businesses
                </li>
            </ul>
            <h5 class="text-muted text-bold" style="font-size: 18px">MSSI APPROACH & RESULT:</h5>
            <ul>
                <li>MSSI to work directly with Infrastructure , Application and Corporate CIO’s and their teams in order
                    to have full IT buy-in
                </li>
                <li>Work vendors including: SAP, Oracle, EMC, HP, Cisco, etc. to assure full coordination</li>
                <li>“Seat at the table” with finance business leaders</li>
                <li>Solution architecture that leveraged existing client Infrastructure delivery tools, processes and
                    resources
                </li>
                <li>Followed relevant client enterprise technology standards, principles and guidelines</li>
                <li>Implemented a robust multi-tiered solution that surpassed the original availability and cost
                    requirements
                </li>
                <li>Provided a flexible architecture that permitted quick cloning of environments used for
                    troubleshooting and future upgrades
                </li>
                <li>Delivered architecture on-time and within budget</li>
                <li>MSSI contract extended to handle future rollouts</li>
            </ul>
            <h5 class="text-muted text-bold" style="font-size: 18px">TECHNOLOGY:</h5>
            <p>AutoSys Licenses, Connect Direct, CA SLA Monitoring, SMARTS Monitoring, Accelatis Software - Ascension
                Hyperion Monitoring, VMWware, Windows Server, Micrsoft SQL, Tripwire, ServiceNow, VNX, Brocade Switches,
                vPLEX Metro, RecoverPoint, VTL, CommVault Backup, HP Blade Chassis & Servers, IBM pSeries, Cisco, F5
                Load Balancers
            </p>
        </div>
    </div>
</div>
<div class="row mt-5"></div>
<app-mssi-footer></app-mssi-footer>
