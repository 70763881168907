<app-mssi-header></app-mssi-header>
<div class="container-fluid ">
    <div class="row "></div>
    <div class="row mt-5"></div>
    <div class="row mt-5">
        <div id="carouselExampleAutoplaying" class="carousel slide" data-bs-ride="carousel">
            <div class="carousel-inner">
                <div class="carousel-item active">
                    <img src="../../../assets/img/c1.jpg" class="d-block w-100" alt="..." style="width: 150px">
                </div>
                <div class="carousel-item">
                    <img src="../../../assets/img/c2.jpg" class="d-block w-100" alt="..." style="width: 150px">
                </div>
                <div class="carousel-item">
                    <img src="../../../assets/img/c3.jpg" class="d-block w-100" alt="..." style="width: 150px">
                </div>
                <div class="carousel-item">
                    <img src="../../../assets/img/c4.jpg" class="d-block w-100" alt="..." style="width: 150px">
                </div>
            </div>
            <button class="carousel-control-prev" type="button" data-bs-target="#carouselExampleAutoplaying" data-bs-slide="prev">
                <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                <span class="visually-hidden">Previous</span>
            </button>
            <button class="carousel-control-next" type="button" data-bs-target="#carouselExampleAutoplaying" data-bs-slide="next">
                <span class="carousel-control-next-icon" aria-hidden="true"></span>
                <span class="visually-hidden">Next</span>
            </button>
        </div>
    </div>
    <div class="row">
        <div class="col-sm-1 col-12"></div>
        <div class="col-lg-10 col-12 mt-3 text-muted" >
            <p >MSSI brings together experienced, talented and dedicated professionals from a range of disciplines to provide creative technology and business solutions.  We encourage a collaborative, open spirit both within MSSI and in our relationships with our clients.
            </p>
            <p class="mt-4">Our "people philosophy" positions our employees as our most valuable assets, and we invest in their growth, training and satisfaction by providing competitive salaries and benefits.  MSSI cultivates a culture of gender equality, human and labor rights,  teamwork, knowledge-sharing and growth.  Most importantly, MSSI employees are passionate about what they do.
            </p>
            <p class="mt-4">Are you interested in joining a dynamic team of individuals all working towards the goal of great customer satisfaction with the solutions and services provided by MSSI?  At MSSI we are passionate about what we do now, as well as what we can do in the future.
            </p>
            <p class="mt-4">MSSI is always on the lookout for bright, talented people who want to grow their careers in a structured yet very independent environment.  If you think you are a good fit, please <a [routerLink]="['/contact-information']">Contact us</a>, so we can talk.</p>
        </div>
        <div class="col-sm-1 col-12"></div>
    </div>
</div>
<div class="row mt-5"></div>
<app-mssi-footer></app-mssi-footer>
