<app-mssi-header></app-mssi-header>
<div class="container-fluid mt-5">
    <div class="row mt-5"></div>
    <div class="row mt-5"></div>
    <div class="row">
        <div class="col-sm-1 col-12"></div>
        <div class="col-lg-11 col-12 mt-3">
            <p class="text-muted text-bold" style="font-size: 15px"><a [routerLink]="['/home']">Home</a> >>Case Study>>
                Disaster Recovery Site</p>
            <p class="text-muted text-bold font-italic">CLIENT: One of the largest providers of physical security and
                alarm monitoring in the world</p>
            <h5 class="mt-3 text-bold text-muted" style="font-size: 18px">BUSINESS PROBLEM:</h5>
            <p>The company had a lack of a true Disaster Recovery site. While data was backed up, there was no plan in
                place on how to use that data in the case of a catastrophic even at their primary location. There was
                also no established RTO (Recovery Time Objective) or RPO (Recovery Point Objective)
            </p>
            <h5 class="text-muted text-bold" style="font-size: 18px">SOLUTION:</h5>
            <ul>
                <li>24 hour RPO</li>
                <li>24 Hour RTO</li>
            </ul>
            <h5 class="text-muted text-bold" style="font-size: 18px">SOLUTION:</h5>
            <ul>
                <li>Create a simple process for recovery</li>
                <li>Create a non disruptive DR test plan to the existing production environment</li>
                <li>Create an architecture that will handle geographically diverse data centers</li>
                <li>Create a DR environment that had 100% equal capacity to the production environment</li>
            </ul>
            <h5 class="text-muted text-bold" style="font-size: 18px">MSSI APPROACH & RESULT:</h5>
            <ul>
                <li>MSSI’s architects worked with the client and multiple vendors to determine which solution(s) would
                    achieve the customer requirements
                </li>
                <li>MSSI leveraged EMC Recovery technologies in order to replicate data to the chosen geographically
                    diverse datacenter surpassing client requirements delivering a near instantaneous RPO
                </li>
                <li>Implemented digital technology to consolidate multiple legacy analog security monitoring devices
                    that would need to remain in the primary data center
                </li>
                <li>Ensured network capacity could handle the RPO now, as well as in the future</li>
                <li>MSSI worked with a the client to find an alternate location for call center personnel</li>
                <li>MSSI completed the first DR Test with client and were successful in beating both the RTO and RPO
                </li>
            </ul>
            <h5 class="text-muted text-bold" style="font-size: 18px">TECHNOLOGY:</h5>
            <p>EMC Data Replication, Database Replication, VMWare / VDI, AT&T & Verizon MPLS, MOXA networking devices,
                IBM P Series, HP Intel Servers, Cisco Switches, Brocade switches, Client specific Security monitoring
                software
            </p>

        </div>
    </div>
</div>
<div class="row mt-5"></div>
<app-mssi-footer></app-mssi-footer>
