<app-mssi-header></app-mssi-header>
<div class="container-fluid ">
    <div class="row"></div>
    <div class="row mt-5"></div>
    <div class="row mt-5">
        <img class="img-fluid" src="../../../assets/img/cl.png">
    </div>
    <div class="row">
        <div class="col-sm-1 col-12"></div>
        <div class="col-lg-4 col-12 mt-3">
            <h5 class="text-bold">Our Office
                Locations</h5>
            <ul class="text-muted">
                <li>Westborough, Massachusetts, United States</li>
                <li>Trichy, Tamilnadu, India</li>
                <li>Bangalore, Karnataka, India</li>
                <li>Chennai, Tamilnadu, India</li>
                <li>Madurai,Tamilnadu, India</li>
            </ul>
        </div>
    </div>
</div>
<div class="row mt-5"></div>
<app-mssi-footer></app-mssi-footer>
