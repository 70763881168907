import {Component} from "@angular/core";

@Component({
    selector: 'careers',
    templateUrl: './careers.component.html',
    styleUrls: ['./careers.component.scss'],
})

export class CareersComponent {

}