import {Component} from "@angular/core";

@Component({
    selector: 'ongoing-support',
    templateUrl: './ongoing-support.component.html',
    styleUrls: ['./ongoing-support.component.scss'],
})

export class OngoingSupportComponent {

}