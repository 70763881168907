<nav class="navbar navbar-expand-lg bg-light fixed-top">
    <div class="container-fluid">
        <a href="#" class="navbar-brand">
            <img class="img-fluid" src="./assets/img/vh%20bg-2.png">
        </a>
        <button type="button" class="navbar-toggler" data-bs-toggle="collapse" data-bs-target="#navbarCollapse">
            <span class="navbar-toggler-icon"><i class="fa fa-bars" aria-hidden="true"></i></span>
        </button>
        <div class="collapse navbar-collapse justify-content-end" id="navbarCollapse">
            <div class="navbar-nav text-bold">
                <a href="" class="nav-item nav-link active-nav">Home</a>
                <a type="button" class="nav-item nav-link">About</a>
                <a type="button" class="nav-item nav-link">Appointment</a>
                <a type="button" class="nav-item nav-link ">Department</a>
                <a type="button" class="nav-item nav-link">Doctors</a>
                <a type="button" class="nav-item nav-link">Services</a>
                <a type="button" class="nav-item nav-link">Contacts</a>
            </div>
        </div>
    </div>
</nav>

<div class="container-fluid mt-5">
    <div class="row p-0 mt-5" id="home-section">
        <!--<div id="carouselExampleCaptions" class="carousel slide mt-5">
          <div class="carousel-indicators">
            <button type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide-to="0" class="active" aria-current="true" aria-label="Slide 1"></button>
            <button type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide-to="1" aria-label="Slide 2"></button>
            <button type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide-to="2" aria-label="Slide 3"></button>
          </div>
          <div class="carousel-inner">
            <div class="carousel-item active">
              <img src="./assets/img/doc1.jpg" class="d-block w-100" alt="doctor1">
              <div class="carousel-caption d-none d-md-block">
                <h5>First slide label</h5>
                <p>Some representative placeholder content for the first slide.</p>
              </div>
            </div>
            <div class="carousel-item">
              <img src="./assets/img/doc2.jpg" class="d-block w-100" alt="doctor2">
              <div class="carousel-caption d-none d-md-block">
                <h5>Second slide label</h5>
                <p>Some representative placeholder content for the second slide.</p>
              </div>
            </div>
            <div class="carousel-item">
              <img src="./assets/img/doc3.jpg" class="d-block w-100" alt="doctor3">
              <div class="carousel-caption d-none d-md-block">
                <h5>Third slide label</h5>
                <p>Some representative placeholder content for the third slide.</p>
              </div>
            </div>
          </div>
          <button class="carousel-control-prev" type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide="prev">
            <span class="carousel-control-prev-icon" aria-hidden="true"></span>
            <span class="visually-hidden">Previous</span>
          </button>
          <button class="carousel-control-next" type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide="next">
            <span class="carousel-control-next-icon" aria-hidden="true"></span>
            <span class="visually-hidden">Next</span>
          </button>
        </div>-->
        <img src="./assets/img/background-main.jpg" class="img-fluid w-100 mt-5">
    </div>

    <section id="about-section" class="site-section" data-aos="fade-up-right" data-aos-duration="3000">
        <div class="container-fluid col-lg-11">
            <div class="row">
                <div class="col-lg-4">
                    <p><img src="./assets/img/happy%20to%20serve%20you.png" alt="Image" class="img-fluid h-75"></p>
                </div>
                <div class="col-lg-8 p-3">
                    <span class="subheading">About Us</span>
                    <h2 class="heading"><strong class="text-primary">We Are Happy To Serve You!</strong></h2>
                    <h4 class="text-muted text-bold">At Varshini Hospital, we extend our warmest greetings to you. Your
                        health and
                        well-being are at the heart
                        of our mission, and we are genuinely happy to serve you with the utmost care and
                        compassion.</h4>
                    <div class="row mt-3">
                        <div class="col-lg-12 col-12">
                            <h2 class="text-primary text-bold">We are dedicated to providing</h2>
                        </div>
                    </div>
                    <div class="row mt-2">
                        <div class="col-lg-12 col-12">
                            <h5 class="text-gray text-bold">Comprehensive Healthcare</h5>
                            <h5 class="text-muted ml-5">Your health is our priority. Our state-of-the-art facilities and
                                skilled
                                medical professionals are here to offer you comprehensive healthcare services.</h5>
                        </div>
                    </div>
                    <div class="row mt-2">
                        <div class="col-lg-12 col-12">
                            <h5 class="text-gray text-bold">Patient-Centric Approach</h5>
                            <h5 class="text-muted ml-5">You are not just a patient; you are a valued individual. Our
                                patient-centric
                                approach ensures that your unique needs are understood and addressed with empathy.</h5>
                        </div>
                    </div>
                    <div class="row mt-2">
                        <div class="col-lg-12 col-12">
                            <h5 class="text-gray text-bold">Excellence in Medical Care</h5>
                            <h5 class="text-muted ml-5">We strive for excellence in every aspect of medical care. From
                                diagnostics to
                                treatment and rehabilitation, expect the highest standards in healthcare delivery.</h5>
                        </div>
                    </div>
                    <!--<div class="row mt-4">
                      <div class="col-lg-3 col-6">
                        <button type="button" class="btn btn-primary rounded-pill">Contact Us</button>
                      </div>
                    <div class="col-lg-3 col-6">
                      <button type="button" class="btn btn-success rounded-pill">Read More</button>
                    </div>
                  </div>-->
                </div>
            </div>
        </div>
    </section>

    <section class="container-fluid">
        <div class="row text-center" data-aos="fade-left" data-aos-duration="3000" *ngIf="showContentTwo">
            <h1 class="text-bold text-primary">About the Varshini Hospital</h1>
            <h4 class="text-muted font-weight-bold mt-2">At Varshini Hospital, your health is our top priority. We are a
                leading healthcare
                institution at KK Nagar, Trichy specializing in diabetic care and general health care. With a commitment
                to
                excellence and a focus on personalized patient care, we are proud to be your healthcare partner.</h4>
        </div>

        <div class="row mt-5 ml-5">
            <div class="col-lg-4 col-12 mt-sm-3 mb-3">
                <div class="row">
                    <div class="col-2">
                        <img class="img-fluid rounded mt-2" src="../../../assets/img/medical-assistance.png">
                    </div>
                    <div class="col-10">
                        <h3 class="text-bold">Professional Doctors</h3>
                        <p class="text-muted">Experience unparalleled healthcare with our team of dedicated and
                            professional doctors
                            committed to your well-being.</p>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-12 mt-sm-3 mb-3">
                <div class="row">
                    <div class="col-2">
                        <img class="img-fluid rounded mt-2" src="../../../assets/img/ambulance.png">
                    </div>
                    <div class="col-10">
                        <h3 class="text-bold">Emergency Help</h3>
                        <p class="text-muted">Swift, reliable emergency assistance at your fingertips—your safety is our
                            priority.</p>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-12 mt-sm-3 mb-3">
                <div class="row">
                    <div class="col-2">
                        <img class="img-fluid rounded mt-2" src="../../../assets/img/like.png">
                    </div>
                    <div class="col-10">
                        <h3 class="text-bold">Premium Healthcare</h3>
                        <p class="text-muted">Elevate your well-being with our premium healthcare services, where
                            excellence meets
                            compassion.</p>
                    </div>
                </div>
            </div>
        </div>

        <div class="row ml-5">
            <div class="col-lg-4 col-12 mt-sm-3 mb-3 ">
                <div class="row">
                    <div class="col-2">
                        <img class="img-fluid rounded mt-2" src="../../../assets/img/drugstore.png">
                    </div>
                    <div class="col-10">
                        <h3 class="text-bold">Exclusive Pharmacy</h3>
                        <p class="text-muted">Your health, our priority – experience convenience and quality at our
                            exclusive
                            pharmacy.</p>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-12 mt-sm-3 mb-3 ">
                <div class="row">
                    <div class="col-2">
                        <img class="img-fluid rounded mt-2" src="../../../assets/img/diagnostic.png">
                    </div>
                    <div class="col-10">
                        <h3 class="text-bold">Exclusive Scan Centre</h3>
                        <p class="text-muted">Discover precision diagnostics at our Exclusive Scan Centre, where
                            cutting-edge
                            technology meets compassionate care.</p>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-12 mt-sm-3 mb-3">
                <div class="row">
                    <div class="col-2">
                        <img class="img-fluid rounded mt-2" src="../../../assets/img/flask.png">
                    </div>
                    <div class="col-10">
                        <h3 class="text-bold">Laboratory Analysis</h3>
                        <p class="text-muted">Accurate insights for informed health decisions – trust our laboratory
                            analysis for
                            precision diagnostics</p>
                    </div>
                </div>
            </div>
        </div>
    </section>

    <section class="container-fluid mb-3">
        <div class="row text-center" data-aos="fade-right" data-aos-duration="3000" *ngIf="showContentTwo">
            <h1 class="text-bold text-primary">Anitha Medicals</h1>
            <h5 class="text-gray text-bold">Easy access to lifesaving drugs. Anytime, every time</h5>
            <h4 class="text-muted font-weight-bold mt-2">Varshini Hospital has an in-house pharmacy department to ensure
                that
                the right medicines are received at the right time, through a highly efficient and economical system. We
                adopt
                and
                apply the best pharmaceutical practices to help maximise drug efficacy and minimise drug toxicity.</h4>

            <div class="row mt-3">
                <div class="col-lg-12 col-12">
                    <h3 class="text-bold text-gray">Facilities</h3>
                </div>
            </div>
        </div>


        <div class="row mt-3">
            <div class="col-lg-4 col-12">
                <h5 class="text-muted ml-5">
                    <li>Quality patient care services</li>
                </h5>
            </div>
            <div class="col-lg-4 col-12">
                <h5 class="text-muted ml-5">
                    <li>Completely computerised system
                    </li>
                </h5>
            </div>
            <div class="col-lg-4 col-12">
                <h5 class="text-muted ml-5">
                    <li>Well-trained and qualified pharmacists
                    </li>
                </h5>
            </div>
        </div>

        <div class="row mt-3 mb-5">
            <div class="col-lg-4 col-12">
                <h5 class="text-muted ml-5">
                    <li>Well-stocked with all necessary pharmaceutical and medical products
                    </li>
                </h5>
            </div>
            <div class="col-lg-4 col-12">
                <h5 class="text-muted ml-5">
                    <li>Disposables, surgical, anti-cancer and lifesaving drugs are also available
                    </li>
                </h5>
            </div>
            <div class="col-lg-4 col-12">
                <h5 class="text-muted ml-5">
                    <li>Adheres to guidelines as per Drugs & Cosmetics Act of 1948 for Pharmacy Management
                    </li>
                </h5>
            </div>
        </div>
    </section>

    <div class="container-fluid p-4 text-white mt-3" style="background: rgba(60,144,247,0.8);color: #fff;">
        <div class="row">
            <div class="container col-lg-10">
                <div class="row text-center">
                    <div class="col-lg-4 col-12">
                        <p class="count"><b>{{staffCount}} +</b></p>
                        <h4 class="text-bold">Our Staffs</h4>
                    </div>
                    <div class="col-lg-4 col-12">
                        <p class="count"><b>{{roomsCount}} +</b></p>
                        <h4 class="text-bold">Clinic Rooms</h4>
                    </div>
                    <div class="col-lg-4 col-12">
                        <p class="count"><b>{{happyClients}} +</b></p>
                        <h4 class="text-bold">Happy Patients</h4>
                    </div>
                    <!--<div class="col-lg-3 col-12">
                      <p class="count"><b>130+</b></p>
                      <h4 class="text-bold">Our Machines</h4>
                      <p class="mt-4">Quisque volutpat mattis eros. Nullam malesuada erat ut turpis.</p>
                    </div>-->
                </div>
            </div>
        </div>
    </div>


    <section id="appointment-section" class="mt-5">
        <div class="container mt-3">
            <div class="appointment-box">
                <div class="row">
                    <div class="col-lg-6 col-md-6 col-12">
                        <div class="title-section">
                            <h1 class="text-bold text-primary">Make an Appointment</h1>
                            <p class="text-gray text-bold">We are here for you. </p>
                        </div>
                        <form class="appointment-form" [formGroup]="appointmentForm" (submit)="onSubmit();">
                            <div class="row">
                                <div class="col-sm-6 mb-3">
                                    <input type="text" name="first-name" id="first-name" placeholder="First Name*"
                                           formControlName="patientFirstName">
                                    <span class="text-danger ml-2 font-weight-bold"
                                          *ngIf="appointmentFormErrors.patientFirstName">{{appointmentFormErrors.patientFirstName}}</span>
                                </div>
                                <div class="col-sm-6 mb-3">
                                    <input type="text" name="last-name" id="last-name" placeholder="Last Name*"
                                           formControlName="patientLastName">
                                    <span class="text-danger ml-2 font-weight-bold"
                                          *ngIf="appointmentFormErrors.patientLastName">{{appointmentFormErrors.patientLastName}}</span>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-sm-6 mb-3">
                                    <input type="text" name="phone-numb" id="phone-numb" placeholder="Phone Number*"
                                           formControlName="phoneNo" (keypress)="OnlyNumbers($event)" minlength="10"
                                           maxlength="10">
                                    <span class="text-danger ml-2 font-weight-bold"
                                          *ngIf="appointmentFormErrors.phoneNo">{{appointmentFormErrors.phoneNo}}</span>
                                </div>
                                <div class="col-sm-6 mb-3">
                                    <input type="text" name="email" id="email" placeholder="Email*"
                                           formControlName="emailId">
                                    <span class="text-danger ml-2 font-weight-bold"
                                          *ngIf="appointmentFormErrors.emailId">{{appointmentFormErrors.emailId}}</span>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-sm-6 mb-3">
                                    <input type="date" name="app-date" id="app-date" placeholder="Appointment Date*"
                                           [min]="currentDate" formControlName="appointmentDate">
                                    <span class="text-danger ml-2 font-weight-bold"
                                          *ngIf="appointmentFormErrors.appointmentDate">{{appointmentFormErrors.appointmentDate}}</span>
                                </div>
                                <div class="col-sm-6 mb-3">
                                    <input type="text" name="patient" id="patient" placeholder="Patient Name*"
                                           formControlName="patientName">
                                    <span class="text-danger ml-2 font-weight-bold"
                                          *ngIf="appointmentFormErrors.patientName">{{appointmentFormErrors.patientName}}</span>
                                </div>
                            </div>
                            <!--<textarea placeholder="Message" formControlName="message"></textarea>-->
                            <div class="row mt-3">
                                <div class="col-sm-12 text-center">
                                    <input type="submit" name="submit-appointment" id="submit-appointment"
                                           value="Submit">
                                </div>
                            </div>
                        </form>
                    </div>

                    <div class="col-lg-6 col-12 text-center" *ngIf="showContent"
                         data-aos="zoom-in-down" data-aos-duration="3000">
                        <img class="img-fluid animated rounded h-75 mt-5 p-4" src="../../../assets/img/appointment.png">
                    </div>
                </div>
            </div>
        </div>
    </section>

    <section class="container-fluid" id="department-section">
        <div class="row col-lg-11 col-12">
            <h1 class="text-bold text-primary ml-lg-5">Our Departments</h1>
        </div>

        <div class="row justify-content-center">
            <div class="col-lg-11 col-md-12 col-12">
                <swiper [config]="config" [spaceBetween]="20" [navigation]="true">
                    <ng-template swiperSlide>
                        <div class="card border-dark">
                            <!--<img class="card-img-top img-fluid" src="./assets/img/cardiology.jpg" alt="cardiology">-->
                            <div class="card-body">
                                <h2>General Health Care</h2>
                                <p class="text-muted">Our dedicated team of healthcare professionals is committed to
                                    your overall
                                    well-being. From routine check-ups to preventive screenings, we provide personalised
                                    and compassionate
                                    services to keep you and your family in the best health. Trust us with your
                                    healthcare needs, and
                                    embark on a journey to lasting wellness.</p>
                            </div>
                        </div>
                    </ng-template>
                    <ng-template swiperSlide>
                        <div class="card">
                            <!--<img class="card-img-top img-fluid" src="./assets/img/cardiology.jpg" alt="cardiology">-->
                            <div class="card-body">
                                <h2>Diabetology</h2>
                                <p class="text-muted">Our dedicated team of specialists is committed to providing
                                    comprehensive care for
                                    individuals managing diabetes. With a focus on personalized treatment plans,
                                    education, and lifestyle
                                    management, we empower you to live a full and healthy life. Trust in our expertise
                                    to guide you
                                    towards optimal well-being.</p>
                            </div>
                        </div>
                    </ng-template>
                    <ng-template swiperSlide>
                        <div class="card">
                            <!--<img class="card-img-top img-fluid" src="./assets/img/cardiology.jpg" alt="cardiology">-->
                            <div class="card-body">
                                <h2>Gynaecology</h2>
                                <p class="text-muted">Our compassionate team of women's health specialists is here to
                                    provide expert
                                    care at every stage of your life. From routine check-ups to advanced reproductive
                                    health services, we
                                    prioritise your well-being. Trust in our expertise and dedication to ensure your
                                    gynaecological health
                                    is in safe hands. Your journey to optimal women's health begins here.</p>
                            </div>
                        </div>
                    </ng-template>
                    <ng-template swiperSlide>
                        <div class="card">
                            <!--<img class="card-img-top img-fluid" src="./assets/img/cardiology.jpg" alt="cardiology">-->
                            <div class="card-body">
                                <h2>Psychiatry</h2>
                                <p class="text-muted">Discover a pathway to mental wellness at our Psychiatry
                                    Department. Our expert
                                    team of compassionate psychiatrists is dedicated to providing personalised care,
                                    tailored to your
                                    unique needs. Whether you're seeking support for mood disorders, anxiety, or other
                                    mental health
                                    challenges, trust us to guide you on your journey to a healthier mind.</p>
                            </div>
                        </div>
                    </ng-template>
                    <ng-template swiperSlide>
                        <div class="card">
                            <!--<img class="card-img-top img-fluid" src="./assets/img/cardiology.jpg" alt="cardiology">-->
                            <div class="card-body">
                                <h2>Orthopaedics</h2>
                                <p class="text-muted">Our specialised team of orthopaedic experts is committed to
                                    diagnosing, treating,
                                    and restoring musculoskeletal health. From joint pain to sports injuries, trust us
                                    to provide
                                    comprehensive solutions tailored to your unique needs. Regain your active lifestyle
                                    with
                                    expertise.Trust in our expertise to guide you towards optimal well-being.</p>
                            </div>
                        </div>
                    </ng-template>
                    <ng-template swiperSlide>
                        <div class="card">
                            <!--<img class="card-img-top img-fluid" src="./assets/img/cardiology.jpg" alt="cardiology">-->
                            <div class="card-body">
                                <h2>Dermatologist</h2>
                                <p class="text-muted">Our skilled dermatologists offer personalised solutions for all
                                    your skin
                                    concerns. From medical dermatology to advanced cosmetic procedures, we are dedicated
                                    to enhancing your
                                    skin's health and appearance. Step into a world of confidence and radiant skin with
                                    the specialised
                                    care.Trust in our expertise to guide you towards optimal well-being.</p>
                            </div>
                        </div>
                    </ng-template>
                    <ng-template swiperSlide>
                        <div class="card">
                            <!--<img class="card-img-top img-fluid" src="./assets/img/cardiology.jpg" alt="cardiology">-->
                            <div class="card-body">
                                <h2>Physiotherapy</h2>
                                <p class="text-muted">Revitalise your movement and well-being,, our experienced
                                    physiotherapists are
                                    dedicated to restoring your strength and function. Whether recovering from an
                                    injury, managing chronic
                                    conditions, or optimising athletic performance, trust our specialised care to help
                                    you reach your full
                                    potential</p>
                            </div>
                        </div>
                    </ng-template>
                </swiper>
            </div>
        </div>
    </section>


    <section class="container-fluid" id="doctors-section">
        <div class="row col-lg-11 col-12">
            <h1 class="text-bold text-primary ml-lg-5 mt-5">Our Dedicated Doctors</h1>
        </div>

        <div class="row mt-4 justify-content-center">
            <div class="col-lg-11 col-md-12 col-12">
                <swiper [config]="config" [spaceBetween]="20" [navigation]="true">
                    <ng-template swiperSlide>
                        <div class="card team-inner">
                            <!-- <img class="card-img-top img-fluid" src="./assets/img/cardiology.jpg" alt="cardiology">-->
                            <div class="card-body">
                                <div class="">
                                    <h3>Dr. B. MURUGA PRAKASH</h3>
                                    <h5>MD, D.Diab.,</h5>
                                    <span>Physician & Diabetologist</span>
                                </div>
                            </div>
                        </div>
                    </ng-template>
                    <ng-template swiperSlide>
                        <div class="card team-inner">
                            <!-- <img class="card-img-top img-fluid" src="./assets/img/cardiology.jpg" alt="cardiology">-->
                            <div class="card-body">
                                <h3 class="text-bold">Dr. M. HARILAKSHMI</h3>
                                <h5>MS, OG.,</h5>
                                <span>Gynecologist</span>
                            </div>
                        </div>
                    </ng-template>
                    <ng-template swiperSlide>
                        <div class="card team-inner">
                            <!-- <img class="card-img-top img-fluid" src="./assets/img/cardiology.jpg" alt="cardiology">-->
                            <div class="card-body">
                                <h3 class="text-bold">Dr. ANBAZHAGAN</h3>
                                <h5>MD, DPM.,</h5>
                                <span>Psychiaprist</span>
                            </div>
                        </div>
                    </ng-template>
                    <ng-template swiperSlide>
                        <div class="card team-inner">
                            <!-- <img class="card-img-top img-fluid" src="./assets/img/cardiology.jpg" alt="cardiology">-->
                            <div class="card-body">
                                <h3 class="text-bold">Dr. N. ARUN GANDHI</h3>
                                <h5>MS Ortho, D.Ortho.,</h5>
                                <span>Orthopedic Surgeon </span>
                            </div>
                        </div>
                    </ng-template>
                    <ng-template swiperSlide>
                        <div class="card team-inner">
                            <!-- <img class="card-img-top img-fluid" src="./assets/img/cardiology.jpg" alt="cardiology">-->
                            <div class="card-body">
                                <h3 class="text-bold">Dr. R. SEETHA LAKSHMI</h3>
                                <h5>MBBS, DDVL.,</h5>
                                <span>Skin Specialist</span>
                            </div>
                        </div>
                    </ng-template>
                    <ng-template swiperSlide>
                        <div class="card team-inner">
                            <!-- <img class="card-img-top img-fluid" src="./assets/img/cardiology.jpg" alt="cardiology">-->
                            <div class="card-body">
                                <h3 class="text-bold">Dr.VASANTHARAGAVAN</h3>
                                <h5>M.S.,FIAGES,EFIAGES,FALS</h5>
                                <span>General, Laparoscopic and Endoscopic Surgeon</span>
                            </div>
                        </div>
                    </ng-template>
                    <ng-template swiperSlide>
                        <div class="card team-inner">
                            <!-- <img class="card-img-top img-fluid" src="./assets/img/cardiology.jpg" alt="cardiology">-->
                            <div class="card-body">
                                <h3 class="text-bold">Dr. Sridhar</h3>
                                <h5>BP</h5>
                                <span>Physiotherapist</span>
                            </div>
                        </div>
                    </ng-template>
                </swiper>
            </div>
        </div>
    </section>

    <section class="container-fluid mt-3" id="services-section" data-aos="zoom-in-out" data-aos-duration="3000">
        <div class="row">
            <div class="col-lg-4 col-12 text-center">
                <img src="./assets/img/Researchers.png" class="img-fluid animated" alt="Our services"
                     style="height: 450px">
            </div>
            <div class="col-lg-8 col-12">
                <div class="inner-services-box">
                    <div class="title-section mt-4">
                        <h1 class="font-weight-bolder text-primary mt-5">Our Services</h1>
                    </div>
                    <div class="services-items">
                        <div class="row mt-4">
                            <div class="col-sm-6">
                                <div class="">
                                    <h3 class="text-bold">Diabetic Care</h3>
                                    <p class="text-muted">Leaders in Diabetic Disease Management – Your partner in
                                        personalised care and
                                        wellness.</p>
                                </div>
                            </div>
                            <div class="col-sm-6">
                                <div class="">
                                    <h3 class="text-bold">General & Surgery</h3>
                                    <p class="text-muted">Excellence in General & Surgery – Your trusted destination for
                                        comprehensive
                                        healthcare solutions.</p>
                                </div>
                            </div>
                        </div>
                        <div class="row mt-4">
                            <div class="col-sm-6">
                                <div class="">
                                    <h3 class="text-bold">Consultative & Diagnostic</h3>
                                    <p class="text-muted">Leaders in Consultative & Diagnostic Excellence – Your
                                        dedicated partners in
                                        precision healthcare.</p>
                                </div>
                            </div>
                            <div class="col-sm-6">
                                <div class="">
                                    <h3 class="text-bold">Rehabilitation Studio</h3>
                                    <p class="text-muted">Your dedicated space for transformative rehabilitation and
                                        wellness.</p>
                                </div>
                            </div>
                        </div>
                        <div class="row mt-4">
                            <div class="col-sm-6">
                                <div class="">
                                    <h3 class="text-bold">Diagnosis With Precise</h3>
                                    <p class="text-muted">Precise Diagnosis, Expert Care – Your trusted destination for
                                        accurate
                                        healthcare solutions.</p>
                                </div>
                            </div>
                            <!--<div class="col-sm-6">
                              <div class="services-post">
                                <span>06.</span>
                                <h2>Diagnosis With <br>Precise</h2>
                                <p>Pellentesque odio nisi, euismod in, pharetra a, ultricies in, diam. Sed arcu. Cras consequat.</p>
                              </div>
                            </div>-->
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>

    <section class="container-fluid mt-3 p-2" id="contact-section" style="color: #fff;" data-aos="fade-right"
             data-aos-easing="ease-in-sine">
        <div class="container quick-contact">
            <div class="row">
                <div class="col-lg-4 col-12 mt-3">
                    <a href="tel:04312456782" class="link-lg d-flex align-items-center">
                        <span class="icon-phone"><i class="fa fa-phone"></i></span>
                        <div>
                            <span>Give us a call</span>
                            <strong>0431-2456782</strong>
                        </div>
                    </a>
                </div>
                <div class="col-lg-4  col-12 mt-3">
                    <a href="mailto:acc.varshinihospital@gmail.com" target="_blank"
                       class="link-lg d-flex align-items-center">
                        <span class="icon-envelope"><i class="fa fa-envelope"></i></span>
                        <div>
                            <span>Send us a message</span>
                            <strong>acc.varshinihospital@gmail.com</strong>
                        </div>
                    </a>
                </div>
                <div class="col-lg-4  col-12 mt-3">
                    <a target="_blank" href="https://maps.app.goo.gl/AVYY4UgGkTRGw7ux9"
                       class="link-lg d-flex align-items-center">
                        <span class="icon-room"><i class="fa fa-map-marker-alt"></i></span>
                        <div>
                            <span>Visit us</span>
                            <strong>#1, Sundar Nagar, Trichy - 21</strong>
                        </div>
                    </a>
                </div>
            </div>
        </div>
    </section>

    <iframe class="mb-4 mt-4"
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3919.5474575389107!2d78.68166667587958!3d10.769317989379037!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3baa8acb99e336a1%3A0x8054c83a4865b3db!2sVarshini%20Hospitals!5e0!3m2!1sen!2sin!4v1701151783759!5m2!1sen!2sin"
            height="450" style="border:0;width: 100%" allowfullscreen="" loading="lazy"
            referrerpolicy="no-referrer-when-downgrade"></iframe>
</div>

<div class="container-fluid p-0 mt-0">

    <footer class="text-center" style="background-color: #b3c9ff">
        <div class="text-center text-bold text-dark p-3" style="background-color: rgba(181,215,234,0.2);">
            <span>Copyright &copy; {{ currentYear }}  </span>
            <a href="https://varnikcloud.com/"
               style="margin: 0; text-decoration: none"
               target="_blank"
            ><span class="text-dark">  VarNik Systems Services Pvt. Ltd</span></a>
            <span>. </span>
            <span>  All rights reserved.</span>
        </div>
        <div class="text-center text-bold" style="background-color: rgba(181,215,234,0.2);">
      <span class="text-black mr-2 text-end" type="button" [routerLink]="['/terms']">Terms & Conditions <span>|</span>
        <span class="text-black ml-1 text-end" type="button" [routerLink]="['/privacy']">Privacy Policy</span></span>
        </div>
    </footer>

</div>

