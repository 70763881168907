import {Component} from "@angular/core";

@Component({
    selector: 'case5',
    templateUrl: './case5.component.html',
    styleUrls: ['./case5.component.scss'],
})

export class Case5Component {

}
