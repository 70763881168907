<app-mssi-header></app-mssi-header>
<div class="container-fluid mt-5">
    <div class="row mt-5"></div>
    <div class="row mt-5"></div>
    <div class="row">
        <div class="col-sm-1 col-12"></div>
        <div class="col-lg-11 col-12 mt-3">
            <p class="text-muted text-bold" style="font-size: 15px"><a [routerLink]="['/home']">Home</a> >>Case Study
                >>Global Storage Infrastructure Assessment and Ongoing Support</p>
            <p class="font-italic text-muted text-bold"> CLIENT: A worldwide manufacturer/distributer of power and hand
                tools</p>
            <h5 class="mt-3 text-bold text-muted" style="font-size: 18px">BUSINESS PROBLEM:</h5>
            <p>Due to an acquisition key personnel of the acquired company were leaving, which created a risk in the
                Storage and Backup infrastructure support and engineering, which could have resulted in unplanned
                outages.
            </p>
            <h5 class="text-muted text-bold" style="font-size: 18px">SOLUTION:</h5>
            <ul>
                <li>Provide 24x7 support</li>
                <li>Maintain configuration and asset management for the entire storage and backup environments</li>
                <li>Provide Systems monitoring and Incident, Change, and Problem management</li>
            </ul>
            <h5 class="text-muted text-bold" style="font-size: 18px">MSSI APPROACH & RESULT:</h5>
            <ul>
                <li>MSSI sent in a team of architects and engineers to immediately provide on site systems support</li>
                <li>During the assessment found current backup environment was at risk, and implemented a solution</li>
                <li>MSSI assessed all backup and storage related projects within the customers project portfolio, and
                    assigned the work to our team members
                </li>
                <li>MSSI worked with EMC teams from both the acquired and acquiring companies to determine an
                    integration plan
                </li>
                <li>MSSI rolled out EMC ECC monitoring tools to the acquired company’s infrastructure</li>
                <li>Expanded our off shore 24x7 team to handle the acquired company’s workload</li>
            </ul>
            <h5 class="text-muted text-bold" style="font-size: 18px">TECHNOLOGY:</h5>
            <p>EMC Storage Systems, Commvault, ServiceNow, EMC ECC, EMC SMARTS, TSM</p>

        </div>
    </div>
</div>
<div class="row mt-5"></div>
<app-mssi-footer></app-mssi-footer>
