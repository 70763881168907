<app-mssi-header></app-mssi-header>
<div class="container-fluid">
    <div class="row "></div>
    <div class="row mt-5"></div>
    <div class="row mt-5">
        <img class="img-fluid" src="../../../assets/img/solution.jpg">
    </div>

    <div class="row">
        <div class="col-sm-1 col-12"></div>
        <div class="col-lg-7 col-12 mt-3 text-muted">
            <p class="text-bold"><a [routerLink]="['/home']">Home</a>>>Solutions
            </p>
            <p>Today, enterprises must align IT closely with business objectives to stay competitive. MSSI delivers
                enterprise solutions to help customers assess business and technology requirements while delivering
                ongoing IT operations.</p>
            <p>Our experts help plan, implement, operate and manage the right technologies to gain a competitive
                advantage.</p>
            <p>Leverage our offshore and onshore capabilities to take your technology to the next level.</p>
            <p>We have an experienced team ready for your organizational needs. Through a combination of our
                technological expertise, proven methodology and working with you on your business strategy, we will
                enable your company to reach its full potential.</p>
            <p>Interested in how MSSI Solutions can benefit your business?</p>
            <p><a [routerLink]="['/contact-information']">Contact Us</a></p>
        </div>
        <div class="col-lg-3 col-12 mt-5">
            <ul class="text-muted">
                <li><a [routerLink]="['/architecture-design']">Architecture Design</a></li>
                <li><a [routerLink]="['/infrastructure']">Infrastructure Assessments</a></li>
                <li><a [routerLink]="['/risk']">Risk Assessments </a></li>
                <li><a [routerLink]="['/office-setup']">Office Setup</a></li>
                <li><a [routerLink]="['/ongoing-support']">Ongoing Support</a></li>
                <li><a [routerLink]="['/app-development']">Application Development</a></li>
            </ul>
        </div>
    </div>

</div>
<div class="row mt-5"></div>
<app-mssi-footer></app-mssi-footer>
