import {Component} from "@angular/core";
import {AppService} from "@services/app.service";
import {Router} from "@angular/router";
import {NgxSpinnerService} from "ngx-spinner";
import {ToastrService} from "ngx-toastr";
import {UntypedFormControl, UntypedFormGroup, Validators} from "@angular/forms";
import { Subject, takeUntil } from 'rxjs';

@Component({
    selector: 'submit-resume',
    templateUrl: './submit-resume.component.html',
    styleUrls: ['./submit-resume.component.scss'],
})

export class SubmitResumeComponent {
    destroy$: Subject<boolean> = new Subject<boolean>();
    resumeFile: File | null = null;

    constructor(private appService: AppService, private route: Router, private spinner: NgxSpinnerService,
                private toastr: ToastrService) {
    }

    contactUs = new UntypedFormGroup({
        name: new UntypedFormControl('', [Validators.required, Validators.nullValidator]),
        phoneNumber: new UntypedFormControl('', [Validators.required, Validators.nullValidator]),
        emailId: new UntypedFormControl('', [Validators.required, Validators.nullValidator, Validators.pattern("^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$")]),
        subject: new UntypedFormControl('', Validators.required),
        message: new UntypedFormControl('', Validators.required),
        resumeFile: new UntypedFormControl('', Validators.required)
    })

    public contactUsError = {
        name: '',
        phoneNumber: '',
        emailId: '',
        subject: '',
        message:'',
        resumeFile: ''
    }

    registerValidation() {
        this.contactUsError.name = "";
        this.contactUsError.phoneNumber = "";
        this.contactUsError.emailId = "";
        this.contactUsError.subject = "";
        this.contactUsError.message = "";
        this.contactUsError.resumeFile = "";

        let hasError = false;

        if (this.contactUs.get("name")?.invalid) {
            this.contactUsError.name = "*Name is required";
            hasError = true
        }
        /*if (this.contactUs.get("phoneNumber")?.invalid) {
            this.contactUsError.phoneNumber = "*Phone Number is required";
            hasError = true
        }*/
        if (this.contactUs.get("emailId")?.invalid) {
            if(this.contactUs.get('emailId').errors.required)
                this.contactUsError.emailId = "*Email Address is required";
            hasError = true
            if (this.contactUs.get('emailId').errors.pattern) {
                this.contactUsError.emailId = "*Enter Valid Email.(Ex:example@gmail.com)";
                hasError = true;
            }
        }
        if (this.contactUs.get("resumeFile")?.invalid) {
            this.contactUsError.resumeFile = "*Resume File is required";
            hasError = true
        }
        if (!hasError) {
            this.saveContactForm();
        }
    }

    onSubmit() {
        this.registerValidation();
    }

    numberOnly(event: any) {
        const charCode = (event.which) ? event.which : event.keyCode;
        if (charCode > 31 && (charCode < 48 || charCode > 58)) {
            return false;
        }
        return true;
    }

    onFileSelected(event: any) {
        const selectedFile = event.target.files[0];
        this.resumeFile = event.target.files[0];

        if (selectedFile) {
            const allowedTypes = ['application/pdf', 'application/msword', 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'];

            if (allowedTypes.includes(selectedFile.type)) {
                // Valid file type, you can proceed with further logic
                console.log('Selected file is a valid type:', selectedFile);
            } else {
                // Invalid file type, you can display an error message or handle it accordingly
                console.error('Invalid file type. Please select a PDF or Word file.');
            }
        }
    }

    saveContactForm() {
        this.spinner.show();
        this.appService
            .sendResumeDetails(this.contactUs.value , this.resumeFile)
            .pipe(takeUntil(this.destroy$))
            .subscribe((data) => {
                this.spinner.hide();
                console.log("save Data::", data)
                this.toastr.success("Your Resume uploaded, we will contact you, Thank you")
            }, (err: any) => {
                this.spinner.hide();
                this.toastr.error("something went wrong, please try again")
            })
    }

}
