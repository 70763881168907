<app-mssi-header></app-mssi-header>
<div class="container-fluid ">
    <div class="row "></div>
    <div class="row mt-5"></div>
    <div class="row mt-5">
        <img class="img-fluid" src="../../../assets/img/risk.jpg">
    </div>
    <div class="row">
        <div class="col-sm-1 col-12"></div>
        <div class="col-lg-7 col-12 mt-3 text-muted">
            <p class="text-bold"><a href="#">Home</a>>>Risk Assessments</p>
            <p>Our qualified consultants perform IT assessments identifying issues, provide objective solutions and
                advice to solve your business problems and objectives.
            </p>
            <p class="mt-3">Once the assessments are complete we design solutions that will increase your revenue,
                efficiency and productivity.</p>
            <p class="mt-3">Please click on the links to the right to review some of our customer success stories.</p>
            <p class="mt-3">Interested in how MSSI Risk Assessments can benefit your business?</p>
            <p><a [routerLink]="['/contact-information']">Contact US</a></p>
        </div>
        <div class="col-lg-3 col-12 mt-3 text-muted">
            <ul>
                <li><a [routerLink]="['/case4']">Case Study : Disaster Recovery Site</a></li>
                <li><a [routerLink]="['/case5']">Case Study : Legacy Systems Assessment and Ongoing Support</a></li>
            </ul>
        </div>
    </div>

</div>

<app-mssi-footer></app-mssi-footer>
