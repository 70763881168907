import {Component} from "@angular/core";

@Component({
    selector: 'locations',
    templateUrl: './locations.component.html',
    styleUrls: ['./locations.component.scss'],
})

export class LocationsComponent {

}