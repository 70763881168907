<app-mssi-header></app-mssi-header>
<div class="container-fluid mt-5">
    <div class="row mt-5"></div>
    <div class="row mt-5"></div>
    <div class="row">
        <div class="col-sm-1 col-12"></div>
        <div class="col-lg-11 col-12 mt-3">
            <p class="text-muted text-bold" style="font-size: 15px"><a [routerLink]="['/home']">Home</a> >>Case
                Study>>Mobile Application Development</p>
            <p class="font-italic text-muted text-bold">CLIENT: A fortune 500 IT company with both hardware and software
                products used worldwide</p>
            <h5 class="mt-3 text-bold text-muted" style="font-size: 18px">BUSINESS PROBLEM:</h5>
            <p>The client had multiple locations throughout the world, often with multiple buildings on a single campus,
                and multiple conference rooms in those buildings, that could be very hard to find
            </p>
            <h5 class="text-muted text-bold" style="font-size: 18px">SOLUTION:</h5>
            <ul>
                <li>Design a simple to use mobile app that would allow a user to interact with a map of a building to
                    see where a given conference room is located
                </li>
                <li>Create a simple interface for the end user</li>
                <li>Make the display map informative, yet simple (include visible relevant landmarks (restrooms,
                    stairwells, vending/snack areas), and exclude those that are not as well known (network closets,
                    fire extinguisher locations, and column numbers)center (lab).
                </li>
            </ul>
            <h5 class="text-muted text-bold" style="font-size: 18px">MSSI APPROACH & RESULT:</h5>
            <ul>
                <li>MSSI worked with the client’s facilities teams to get accurate maps of existing sites</li>
                <li>Designed simplified color coded maps that would be visually easy for the end user to see the
                    different types of rooms
                </li>
                <li>Created an interactive map, so any conference room that was tapped would bring up conference room
                    details (such as seating capacity, and available IT/AV in the room)
                </li>
                <li>Made the map interactive by allowing zooming and the ability to move around the map</li>
                <li>Created Search feature, with conference rooms searchable by name, and or by number</li>
                <li>Enable a pull down, to ‘drill down’ to the correct location, starting at town, then campus, then
                    building, then floor
                </li>
            </ul>
            <h5 class="text-muted text-bold" style="font-size: 18px">TECHNOLOGY:</h5>
            <p>iOS, Appcelerator, INTEl XDK, JavaScript, Android SDK, Apple's XCode, HTML5, INTEL XDK, JSON Data
                Transfer, BaaS framework</p>
        </div>
    </div>
</div>
<div class="row mt-5"></div>
<app-mssi-footer></app-mssi-footer>
