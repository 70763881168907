import {Component} from "@angular/core";

@Component({
    selector: 'risk',
    templateUrl: './risk.component.html',
    styleUrls: ['./risk.component.scss'],
})

export class RiskComponent {

}