import {Component} from "@angular/core";

@Component({
    selector: 'service-program',
    templateUrl: './service-program.component.html',
    styleUrls: ['./service-program.component.scss'],
})

export class ServiceProgramComponent {

}