import {Component} from "@angular/core";

@Component({
    selector: 'architecture-design',
    templateUrl: './architecture-design.component.html',
    styleUrls: ['./architecture-design.component.scss'],
})

export class ArchitectureDesignComponent {

}